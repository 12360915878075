module.exports = {
  search: {
    limit_words: 32,
  },
  course: {
    promote: [],
    more_info_enroll: [],
    disable_discussion: false,
  },
  console: {
    h5CompleteSetting: false,
    learner_progress_report: false,
    campaign_view: true,
    bundle_course: {
      required_section: [
        "basicInformation",
        "selectedCourse",
        "bundleCourseImage",
        "feeCampaignDuration",
      ],
      required_field: [
        "title",
        "courseImage",
        "price",
        "effectiveStartTime",
        "effectiveEndTime",
      ],
      custom_field: [],
    },
  },
  user: {
    login: {
      btn_sso_login: false, // App not used
      btn_ad_login: false,
      btn_non_ad_login: false,
      btn_social_login_fb: true,
      btn_social_login_google: true,
      display_unauthorized: ["explore"], //
      form_type_login: "basic", // basic|company
      notice_important_name: false,
      it_login_error_message: false,
      it_login_contact_email: "",
    },
    signup: {
      user_signup: true,
    },
  },
  contactUs: {
    display_type: ["basic"], // basic,order
    email: "service.deltaknew@deltaww.com",
  },
  explore: {
    use_tms_adapter: false,
    show_bundle_courses: true,
    guest_view: true,
  },
  footer: {
    custom: "", // null||qrcode
    icp: [],
    customized_copyright: null,
  },
  profile: {
    form_uneditable: "null", // null|all
    form_type_billingInfo: "basic", // null|basic|address_ACRA
    form_extra_billingInfo: null, // null|citizenship
    form_type_phoneNumber: true,
    form_password_edit: true,
  },
  studio: {
    course: {
      use_tms_adapter: false,
      custom_search: [],
      permission: ["create", "remove", "duplicate"], // DKAM
      chapter_permission: ["create", "remove"],
      content_permission: ["create", "remove"],
      custom_field: [],
      hidden_section: [],
      required_section: [
        "basicInformation",
        "tagCategory",
        "courseLanguage",
        "courseSyllabus",
        "feeCampaignDuration",
      ],
      disabled_section: [],
      required_field: [
        "title",
        "skillLevel",
        "category",
        "courseLanguage",
        "chapterTitle",
        "chapterDuration",
        "chapterContent",
        "previewVideoContent",
        "attachment",
        "price",
      ],
      disabled_field: [],
    },
    video: {
      permission: ["create", "remove"], // DKAM
      custom_field: [],
      required_section: [
        "basicInformation",
        "tagCategory",
        "videoImage",
        "manageRole",
        "videoSection",
        "videoContent",
      ],
      disabled_section: [],
      required_field: [
        "title",
        "category",
        "skillLevel",
        "videoImage",
        "instructors",
        "videoSource",
      ],
      disabled_field: [],
    },
    doc: {
      permission: ["create", "remove"], // DKAM
      custom_field: [],
      required_section: ["basicInformation", "manageRole", "docContent"],
      disabled_section: [],
      required_field: ["title", "instructors", "docContent"],
      disabled_field: [],
    },
    asmt: {
      score_limit: 999,
      disabled_field: [],
    },
    h5: {
      permission: [],
      custom_field: [],
      required_section: [],
      disabled_section: [],
      required_field: [],
      disabled_field: [],
    },
  },
  download: {
    show_app_download_screen: true,
  },
  system: {
    forceLang: "", // "en", "zh-Hant", "zh-Hans"
    fallbackLang: "en", // "en", "zh-Hant", "zh-Hans"
  },
};
