import * as React from "react";

const CourseRelation = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        key="CourseRelation-icon-path-1"
        d="M11.5 14.9V7.5C12.6 6.8 14.7 6.2 16.2 6.2C17.2 6.2 18.3 6.3 19.2 6.6V8.3C19.8 8.4 20.4 8.7 20.9 9V5.8C19.6 4.8 17.8 4.5 16.2 4.5C14.5 4.5 12.7 4.8 11.5 5.8C10.3 4.9 8.5 4.5 6.8 4.5C5.1 4.5 3.3 4.9 2 5.8V18.4C2 18.9 2.7 18.8 2.7 18.8C3.8 18.2 5.5 17.8 6.8 17.8C7.8 17.8 8.9 17.9 9.8 18.2C9.8 18.1 9.8 18.1 9.8 18C9.8 16.7 10.5 15.5 11.5 14.9Z"
        fill={props.fill || "#727171"}
      />
      <path
        key="CourseRelation-icon-path-2"
        d="M17.2 10.8C18 10.8 18.6 11.4 18.6 12.2C18.6 13 18 13.6 17.2 13.6C16.4 13.6 15.8 13 15.8 12.2C15.8 11.4 16.4 10.8 17.2 10.8ZM17.2 17.9C19.1 17.9 21.3 18.8 21.5 19.3H13C13.1 18.8 15.3 17.9 17.2 17.9ZM17.2 9.40002C15.6 9.40002 14.4 10.7 14.4 12.2C14.4 13.7 15.7 15 17.2 15C18.7 15 20 13.7 20 12.2C20 10.7 18.8 9.40002 17.2 9.40002ZM17.2 16.5C15.3 16.5 11.5 17.5 11.5 19.3V20.8H22.9V19.4C22.9 17.5 19.1 16.5 17.2 16.5Z"
        fill={props.fill || "#727171"}
      />
    </svg>
  );
};

export default CourseRelation;
