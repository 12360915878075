module.exports = {
  ANDROID_DOWNLOAD_LINK: process.env.ANDROID_DOWNLOAD_LINK,
  ANDROID_INSTALL_GUIDE: process.env.ANDROID_INSTALL_GUIDE,
  APP_DEEPLINK_PREFIX: process.env.APP_DEEPLINK_PREFIX,
  APP_LOGO_URL: process.env.APP_LOGO_URL,
  ENABLE_SENTRY: process.env.ENABLE_SENTRY,
  IOS_DOWNLOAD_LINK: process.env.IOS_DOWNLOAD_LINK,
  IOS_INSTALL_GUIDE: process.env.IOS_INSTALL_GUIDE,
  NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
  NEXT_PUBLIC_DEBUG: process.env.NEXT_PUBLIC_DEBUG,
  NEXT_PUBLIC_HEAD_TITLE: process.env.NEXT_PUBLIC_HEAD_TITLE,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SSO_CALLBACK: process.env.NEXT_PUBLIC_SSO_CALLBACK,
  NEXT_PUBLIC_SSO_DOMAIN: process.env.NEXT_PUBLIC_SSO_DOMAIN,
  NEXT_PUBLIC_REACT_APP_ENV: process.env.NEXT_PUBLIC_REACT_APP_ENV,
  NEXT_PUBLIC_REACT_APP_FB_APP_ID: process.env.NEXT_PUBLIC_REACT_APP_FB_APP_ID,
  NEXT_PUBLIC_REACT_APP_GOOGLE_APP_ID:
    process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_APP_ID,
  NEXT_PUBLIC_SCM_CONFIG: process.env.NEXT_PUBLIC_SCM_CONFIG,
  NEXT_PUBLIC_CUSTOM_HEADER_LOGO: process.env.NEXT_PUBLIC_CUSTOM_HEADER_LOGO,
  NEXT_PUBLIC_CUSTOM_HEADER_APP_NAME:
    process.env.NEXT_PUBLIC_CUSTOM_HEADER_APP_NAME,
  NEXT_PUBLIC_DEFAULT_COURSE_COVER:
    process.env.NEXT_PUBLIC_DEFAULT_COURSE_COVER,
  SENTRY_ENABLE_TRACING_VIDEO: process.env.SENTRY_ENABLE_TRACING_VIDEO,
  SENTRY_TRACING_ORIGINS: process.env.SENTRY_TRACING_ORIGINS,
  SENTRY_TRACING_RATE: process.env.SENTRY_TRACING_RATE,
  SENTRY_TRACING_VIDEO_NAME: process.env.SENTRY_TRACING_VIDEO_NAME,
  USER_GUIDE_URL_EN: process.env.USER_GUIDE_URL_EN,
  USER_GUIDE_URL_ZH_HANS: process.env.USER_GUIDE_URL_ZH_HANS,
  USER_GUIDE_URL_ZH_HANT: process.env.USER_GUIDE_URL_ZH_HANT,
};
