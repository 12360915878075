import { scm } from "@components/AppEnv";
import sortBy from "lodash/sortBy";
import filter from "ramda/es/filter";
import flatten from "ramda/es/flatten";
import uniq from "ramda/es/uniq";
import { useEffect, useState } from "react";

const showOnlyDepthOne = scm.explore.use_tms_adapter;

export const useCategory = ({
  showAll = false,
  filterCategoryType = "none",
  requirementMode,
} = {}) => {
  const [categoryArray, setCategoryArray] = useState([]);
  const [idMap, setIdMap] = useState({});
  const [updateTaxonomy, setUpdateTaxonomy] = useState(true);
  const [categoryGrouped, setCategoryGrouped] = useState([]);
  const [filteredCategoryIdsGrouped, setFilteredCategoryIdsGrouped] = useState(
    [],
  );
  const [filteredCategoryDict, setFilteredCategoryDict] = useState([]);
  const [filteredCategoryIdMap, setFilteredCategoryIdMap] = useState({});
  const [categoryIdArrays, setCategoryIdArrays] = useState([]);

  const getTaxonomyCategoryResources = async (ignoreCache = false) => {
    import("@utils/apiServices/taxonomy").then(({ getTaxonomy }) => {
      import("@utils/category-utils").then(async ({ groupCategory }) => {
        try {
          const { data, error } = await getTaxonomy({
            depth: showOnlyDepthOne ? 0 : 4, // result depth includes 0 ~ 4
            metadata: true,
            ignoreCache,
            showAll,
          });
          if (error) {
            throw error;
          }
          if (categoryIdArrays.length) {
            const idArraysList = uniq(flatten(categoryIdArrays));
            const filterIdsData = filter(
              (item) => idArraysList.includes(item.categoryId),
              data,
            );
            setFilteredCategoryDict(
              sortBy(filterIdsData, (item) => item.serialNumber),
            );
            setFilteredCategoryIdMap(
              filterIdsData.reduce(
                (prev, item) =>
                  Object.assign(prev, { [item.categoryId]: item }),
                {},
              ),
            );
            setFilteredCategoryIdsGrouped(groupCategory(filterIdsData));
          } else {
            setCategoryArray(sortBy(data, (item) => item.serialNumber));
            setIdMap(
              data.reduce(
                (prev, item) =>
                  Object.assign(prev, { [item.categoryId]: item }),
                {},
              ),
            );
            setCategoryGrouped(groupCategory(data));
          }
          setUpdateTaxonomy(false);
        } catch (error) {
          console.error("getTaxonomyCategoryResources", error);
        }
      });
    });
  };

  const getCategoryFilteredListResource = async () => {
    import("@utils/apiServices/myJournal").then(
      async ({ getCategoryFilteredList }) => {
        try {
          const { data, error } = await getCategoryFilteredList({
            filterCategoryType,
            requirementMode,
          });
          if (error) {
            throw error;
          }
          if (data) {
            setCategoryIdArrays(data);
          }
        } catch (error) {
          console.error("getCategoryFilteredListResource", error);
        }
      },
    );
  };

  const update = () => {
    getTaxonomyCategoryResources(true);
    setUpdateTaxonomy(true);
  };

  useEffect(() => {
    if (filteredCategoryIdsGrouped.length === 0) {
      if (filterCategoryType !== "none") {
        getCategoryFilteredListResource();
      }
    } else if (filterCategoryType === "assigned") {
      getCategoryFilteredListResource();
    }
  }, [filterCategoryType, requirementMode]);

  useEffect(() => {
    getTaxonomyCategoryResources();
  }, [categoryIdArrays]);

  return {
    categoryDict: categoryArray,
    filteredCategoryDict,
    categoryGrouped,
    filteredCategoryIdsGrouped,
    categoryIdMap: idMap,
    filteredCategoryIdMap,
    updateTaxonomy,
    update,
  };
};
