import { localeFallbackLanguage, scm } from "@components/AppEnv";
import { Provider } from "@components/context";
import { useMContext } from "@components/context/mContext";
import DownloadAppInformation from "@components/DownloadAppInformation";
import JudgementBrowsersTypeModal from "@components/JudgementBrowsersTypeModal";
import LangIntlProvider from "@components/LangIntlProvider";
import SmallScreen from "@components/SmallScreen";
import WarningDialog from "@page-components/WarningDialog";
import { useWarningDialogState } from "@page-components/WarningDialog/utils";
import { useCategory } from "@utils/hooks/use-category";
import { useIosDeepLink } from "@utils/hooks/use-deeplink";
import { loginDirectPath } from "@utils/page-utils";
import { compatibleMobileList } from "@utils/route-utils";
import { getCookie, setCookie } from "cookies-next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

const ServiceUnavailable = dynamic(
  () => import("@page-components/ErrorPage/503"),
  {
    ssr: false,
  },
);
// reduce 16kb
const Snackbar = dynamic(() => import("@material-ui/core/Snackbar"), {
  ssr: false,
});

const SHOW_DOWNLOAD_SCREEN = scm.download.show_app_download_screen;

const ComponentWrapper = ({
  data = {},
  children,
}: {
  children: React.ReactNode;
  data: {
    lang: string;
    loginStatus: {
      email: string;
      name: string;
      token: string;
      loginType: string;
      avatar: string;
    };
    userPrivilege: string[];
    userPrivacy: any;
  } & any;
}) => {
  const {
    lang = getCookie("lang") || localeFallbackLanguage,
    loginStatus: loginStatusProps = {
      email: getCookie("email") || "",
      name: getCookie("name") || "",
      token: getCookie("token") || "",
      loginType: getCookie("loginType") || "",
      avatar: getCookie("avatar") || "",
    },
  } = data;
  const router = useRouter();
  const category = useCategory();
  const loginRef = useRef(null);
  const mobileContext = useMContext();
  const [loginStatus, setLoginStatus] = useState(loginStatusProps);
  const [userPrivilege, setUserPrivilege] = useState(data.userPrivilege);
  const [userPrivacy, setUserPrivacy] = useState(data.userPrivacy);
  const [language, setLanguage] = useState(lang);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState<string | React.ReactNode>("");
  const [showServiceUnavailable, setShowServiceUnavailable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [[loginCallback], setLoginCallback] = useState([
    () => {
      // empty initial value
    },
  ]);

  const DownloadAppPage = useCallback(() => {
    if (
      !compatibleMobileList.some((path) => router.pathname.includes(path)) &&
      router.asPath.match("^/m/") === null
    ) {
      if (SHOW_DOWNLOAD_SCREEN) {
        return <DownloadAppInformation />;
      }
      return <SmallScreen />;
    }
    return null;
  }, [router, isMobile]);

  const renderChildren = () => {
    if (
      isMobile &&
      !compatibleMobileList.some((path) =>
        new RegExp(path).test(router.pathname),
      ) &&
      router.asPath.match("^/m/") === null
    ) {
      return DownloadAppPage();
    }
    if (showServiceUnavailable) return <ServiceUnavailable />;
    return (
      <>
        {DownloadAppPage()}
        {children}
      </>
    );
  };

  const showSnackbarMsg = (msg) => {
    setSnackbarMsg(msg);
    setOpenSnackbar(true);
  };

  const showErrorSnackBar = () => {
    setSnackbarMsg(<FormattedMessage id="console.message.systemError" />);
    setOpenSnackbar(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      // close unService status
      setShowServiceUnavailable(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (window) {
      const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
      setIsMobile(isMobileDevice);
    }
  }, []);

  // link to app if installed
  useIosDeepLink();

  useEffect(() => {
    setCookie("avatar", loginStatus.avatar, {
      secure: true,
    });
  }, [loginStatus]);

  useEffect(() => {
    // set user localstorage
    if (router.query.redirect_uri && localStorage) {
      localStorage.setItem("redirect_uri", router.query.redirect_uri as string);
      setLoginCallback([
        () => router.push(loginDirectPath({ router, localStorage })),
      ]);
    } else if (localStorage && localStorage.getItem("redirect_uri") === null) {
      setLoginCallback([
        () =>
          router.push(
            loginDirectPath({
              router,
              localStorage,
              defaultUrl: /^\/m\//.test(router.asPath) ? "/m" : "/",
            }),
          ),
      ]);
    }
  }, [router]);

  const userContext = {
    language,
    setLanguage,
    loginStatus,
    setLoginStatus,
    userPrivilege,
    setUserPrivilege,
    userPrivacy,
    setUserPrivacy,
    showServiceUnavailable,
    setShowServiceUnavailable,
    loginCallback,
    setLoginCallback,
  };

  const snackbarContext = {
    showSnackbarMsg,
    setOpenSnackbar,
    setSnackbarMsg,
    showErrorSnackBar,
  };

  const warningDialogContext = useWarningDialogState();

  const baseContext = {
    ...userContext,
    ...snackbarContext,
    ...warningDialogContext,
    header: {
      category,
    },
    loginRef,
    ...mobileContext,
  };

  return (
    <Provider value={baseContext}>
      <LangIntlProvider>
        {renderChildren()}
        <Snackbar
          classes={{
            anchorOriginBottomLeft: "!bottom-[68px]",
          }}
          disableWindowBlurListener
          open={openSnackbar}
          message={snackbarMsg}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={6000}
          onClose={closeSnackbar}
        />
        <WarningDialog />
        <JudgementBrowsersTypeModal />
      </LangIntlProvider>
    </Provider>
  );
};

export default ComponentWrapper;
