import * as React from "react";

const Iquiz5 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.907">
      <path
        fill={props.fill || "#fff"}
        d="M9.293 13.79h1.4a1.363 1.363 0 001.337-1.379v-1.1l-1.337 1.1M4.145 0H1.07a2.067 2.067 0 00-.669.345 3.99 3.99 0 00-.267.483A1.664 1.664 0 000 1.379v11.032a1.664 1.664 0 00.134.552 2.151 2.151 0 00.267.414.919.919 0 00.669.345h3.744c-.535-.414-.334-1.379-.334-1.379H1.337V1.379H10.7v4s.8-.483 1.337-.207V1.379A1.363 1.363 0 0010.7 0H4.145z"
      />
      <path
        fill={props.fill || "#fff"}
        d="M6.478 13.907a1.166 1.166 0 01-1.123-1.193.763.763 0 01.07-.351 3.131 3.131 0 01.351-.842l.281-.632c0-.07.07-.14.07-.281 0-.07.07-.14.14-.211a.245.245 0 00.07-.14.069.069 0 01.07-.07l4.348-3.789a2.145 2.145 0 011.4-.491 1.186 1.186 0 01.7.211 1.935 1.935 0 01.632.491l.07.07a1.875 1.875 0 01.421 1.4 1.994 1.994 0 01-.632 1.333l-4.342 3.795-.14.14c.07-.07 0-.07 0-.07a.069.069 0 00-.07.07h-.139a11.8 11.8 0 00-1.333.351l-.491.14h-.076a.53.53 0 01-.277.069zm.211-.912zm0 0zm-.421-.491zm1.193-1.263c0 .07-.07.14-.07.211l-.281.632c-.07.14-.07.211-.14.281a11.557 11.557 0 011.193-.281l4.281-3.719a.6.6 0 00.211-.351c0-.07 0-.14-.14-.421-.07-.07-.07-.07-.14-.07h-.07c-.07 0-.14-.07-.211-.07a1 1 0 00-.421.14zm-3.46-.665a.52.52 0 01-.516-.258L2.454 8.941a.61.61 0 01.172-.945.61.61 0 01.945.172l.43.6L5.29 7.136a.671.671 0 111.031.859l-1.89 2.407a.656.656 0 01-.43.174zM2.855 6.639a1.221 1.221 0 01-.516-.172.674.674 0 010-.945l2.75-2.664a.668.668 0 01.945.945L3.285 6.468c-.086.171-.258.171-.43.171z"
      />
      <path
        fill={props.fill || "#fff"}
        d="M5.605 6.639a1.221 1.221 0 01-.516-.172L2.425 3.804a.668.668 0 01.945-.945l2.75 2.664a.674.674 0 010 .945.631.631 0 01-.515.171zm.776-3.805h4.039v1.375H6.381zm0 1.889h4.039v1.375H6.381z"
      />
    </svg>
  );
};

export default Iquiz5;
