import * as React from "react";

const DragDrop = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="6.5" r="1.5" fill={props.fill || "#C4C4C4"} />
      <circle cx="9.5" cy="11.5" r="1.5" fill={props.fill || "#C4C4C4"} />
      <circle cx="9.5" cy="16.5" r="1.5" fill={props.fill || "#C4C4C4"} />
      <circle cx="14.5" cy="6.5" r="1.5" fill={props.fill || "#C4C4C4"} />
      <circle cx="14.5" cy="11.5" r="1.5" fill={props.fill || "#C4C4C4"} />
      <circle cx="14.5" cy="16.5" r="1.5" fill={props.fill || "#C4C4C4"} />
    </svg>
  );
};

export default DragDrop;
