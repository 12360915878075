import * as React from "react";

const North = (props) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 5.75L1.8075 6.8075L5.25 3.3725L5.25 15.5H6.75L6.75 3.3725L10.1925 6.815L11.25 5.75L6 0.5L0.75 5.75Z"
        fill={props.fill || "#8FC351"}
      />
    </svg>
  );
};

export default North;
