import { logger } from "@sentry/utils";

export class RangeableRequestTracingActions {
  public static startTrace;

  public static endTrace;

  public static isTraceStarted;

  public static getTracedRequests;
}

export const startTrace = ({ event = "", data = {} } = {}) => {
  const errMessage = "The integration is not initialized with Sentry.";
  if (!RangeableRequestTracingActions.startTrace) {
    logger.error(errMessage);
    throw new Error(errMessage);
  }
  return RangeableRequestTracingActions.startTrace({ event, data });
};

export const endTrace = ({ event = "", data = {} } = {}) => {
  const errMessage = "The integration is not initialized with Sentry.";
  if (!RangeableRequestTracingActions.endTrace) {
    logger.error(errMessage);
    throw new Error(errMessage);
  }
  return RangeableRequestTracingActions.endTrace({ event, data });
};

export const isTraceStarted = () => {
  const errMessage = "The integration is not initialized with Sentry.";
  if (!RangeableRequestTracingActions.isTraceStarted) {
    logger.error(errMessage);
    throw new Error(errMessage);
  }
  return RangeableRequestTracingActions.isTraceStarted();
};

export const getTracedRequests = () => {
  const errMessage = "The integration is not initialized with Sentry.";
  if (!RangeableRequestTracingActions.getTracedRequests) {
    logger.error(errMessage);
    throw new Error(errMessage);
  }
  return RangeableRequestTracingActions.getTracedRequests();
};
