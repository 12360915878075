import * as React from "react";

const AddSubCategory = (props) => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11 4H0V6H11V4ZM11 0H0V2H11V0ZM15 8V4H13V8H9V10H13V14H15V10H19V8H15ZM0 10H7V8H0V10Z"
        fill={props.fill || "#00ADEF"}
      />
    </svg>
  );
};

export default AddSubCategory;
