import { APP_DEEPLINK_PREFIX } from "@components/AppEnv";
import deepLinkRegex from "@constants/deeplink-regex";
import { isMobile } from "@utils/device-utils";
import { useRouter } from "next/router";
import { useEffect } from "react";

const checkPathMatch = (router) => {
  const { query = {}, asPath = "" } = router;
  const { redirect_uri: redirectUri = "" } = query;
  const isMatchedRouterRedirectUrl = deepLinkRegex.some((regexItem) =>
    redirectUri.match(regexItem),
  );
  const isMatchedPathname = deepLinkRegex.some((regexItem) =>
    asPath.match(regexItem),
  );
  if (isMatchedRouterRedirectUrl) return redirectUri;
  if (isMatchedPathname) return asPath;
  return false;
};

export const useIosDeepLink = () => {
  const router = useRouter();
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (isMobile.any() && APP_DEEPLINK_PREFIX) {
        const matchedPath = checkPathMatch(router);
        if (matchedPath) {
          const appRoute =
            matchedPath === "/"
              ? `${APP_DEEPLINK_PREFIX}://explore`
              : `${APP_DEEPLINK_PREFIX}:/${matchedPath}`;
          window.location.assign(appRoute);
        }
      }
    }, 50);
    return () => {
      clearTimeout(redirectTimer);
    };
  }, []);
};

export default useIosDeepLink;
