import { NEXT_PUBLIC_HEAD_TITLE } from "@components/AppEnv";
import Head from "next/head";
import React from "react";

const headTitle = NEXT_PUBLIC_HEAD_TITLE || "Academy";

const metaLimitInfo = {
  ogDescription: 160,
  ogTitle: 60,
};

// for disable nextjs error dialog
const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;

const textSlicer = ({ string, length }) => {
  if (string && string.length > length) {
    return `${string.slice(0, length - 3)}...`;
  }
  return string;
};

const Scaffold = ({ pageProps }) => {
  return (
    <Head>
      <title>{headTitle}</title>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no"
      />
      {pageProps.ogImage && (
        <meta property="og:image" content={pageProps.ogImage} />
      )}
      {pageProps.ogTitle && (
        <meta
          property="og:title"
          content={textSlicer({
            string: pageProps.ogTitle,
            length: metaLimitInfo.ogTitle,
          })}
        />
      )}
      {pageProps.ogDescription && (
        <meta
          property="og:description"
          content={textSlicer({
            string: pageProps.ogDescription,
            length: metaLimitInfo.ogDescription,
          })}
        />
      )}
      {process.env.NEXT_PUBLIC_DISABLE_ERROR_DIALOG && (
        <script
          dangerouslySetInnerHTML={{ __html: noOverlayWorkaroundScript }}
        />
      )}
    </Head>
  );
};

export default Scaffold;
