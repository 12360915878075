import * as React from "react";

const CertificateSVG = ({ fill = "#B5B5B6" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.87581 21.5446C3.43872 21.5446 3.05021 21.3946 2.71026 21.0446C2.3703 20.6946 2.22461 20.2946 2.22461 19.8446V4.94463C2.22461 4.49463 2.3703 4.09463 2.71026 3.74463C3.05021 3.39463 3.43872 3.24463 3.87581 3.24463H20.6792C21.1162 3.24463 21.5048 3.39463 21.8447 3.74463C22.1847 4.09463 22.3304 4.49463 22.3304 4.94463V19.8446C22.3304 20.2946 22.1847 20.6946 21.8447 21.0446C21.5048 21.3946 21.1162 21.5446 20.6792 21.5446H3.87581ZM3.87581 19.8446H20.6792V4.94463H3.87581V19.8446ZM5.6727 17.3946H10.4806V15.3946H5.6727V17.3946ZM5.6727 13.3946H10.4806V11.3946H5.6727V13.3946ZM5.6727 9.39463H10.4806V7.39463H5.6727V9.39463ZM3.87581 19.8446V4.94463V19.8446Z"
        fill={fill}
      />
      <path
        d="M13.9775 4.74463V11.3446L16.6 10.0446L19.174 11.3446V4.74463H13.9775Z"
        fill={fill}
      />
      <path
        d="M14.8513 18.1946L19.3192 13.5946L18.008 12.2446L14.8513 15.5446L13.54 14.1946L12.2773 15.5446L14.8513 18.1946Z"
        fill={fill}
      />
    </svg>
  );
};

export default CertificateSVG;
