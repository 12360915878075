import { INTRA_API_BASE_URL } from "@configs/nodeEnv";
import { getConfigTimeout, parseError } from "@utils/api-utils";
import { finishTransaction, getTransactionInList } from "@utils/sentry-utils";
import axios from "axios";

if (INTRA_API_BASE_URL === "") {
  throw Error("INTRA_API_BASE_URL should not be empty");
}

const nodeAPI = axios.create({
  baseURL: INTRA_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
  withCredentials: true,
  credentials: "same-origin",
});

nodeAPI.interceptors.request.use((config) => {
  return {
    ...config,
    timeout: getConfigTimeout(config),
    sentryTransaction: getTransactionInList(config),
  };
});

nodeAPI.interceptors.response.use(
  (response) => {
    const { config } = response;
    finishTransaction({ config, response });
    return response;
  },
  (error) => {
    const { config } = error;
    finishTransaction({ config, error });

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...parseError(error) });
  },
);

export default nodeAPI;
