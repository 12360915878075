import * as React from "react";

const SortArrow = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83301 9.16667L9.99967 5L14.1663 9.16667H5.83301Z"
        fill={props.fill || "#B5B5B6"}
      />
      <path
        d="M5.83301 10.8335L9.99967 15.0002L14.1663 10.8335H5.83301Z"
        fill={props.fill || "#B5B5B6"}
      />
    </svg>
  );
};

export default SortArrow;
