import * as React from "react";

const AdvancedLevel = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fill={props.fill || "#666565"}
        d="M5 14h3v6H5v-6zm6-5h3v11h-3V9zM17 4h3v16h-3V4z"
      />
    </svg>
  );
};

export default AdvancedLevel;
