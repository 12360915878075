import * as React from "react";

const CheckList = ({ fill = "#C9C9C9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={"#C9C9C9"}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 6.99969H13V8.99969H22V6.99969ZM22 14.9997H13V16.9997H22V14.9997ZM5.54 10.9997L2 7.45969L3.41 6.04969L5.53 8.16969L9.77 3.92969L11.18 5.33969L5.54 10.9997ZM5.54 18.9997L2 15.4597L3.41 14.0497L5.53 16.1697L9.77 11.9297L11.18 13.3397L5.54 18.9997Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckList;
