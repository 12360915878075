import * as React from "react";

const AssessmentSVG = (props = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <g fill={props.fill || "#3F3F3F"} clipPath="url(#prefix__clip0)">
        <path d="M15.9 24.637h2.4a2.335 2.335 0 002.287-2.364v-1.891L18.3 22.273l-2.4 2.364zM7.091 1H1.83a3.54 3.54 0 00-1.144.591 6.862 6.862 0 00-.457.827c-.13.3-.207.62-.229.946v18.909c.022.326.1.645.229.945.125.253.278.49.457.709a1.56 1.56 0 001.144.591h6.4c-.915-.709-.572-2.364-.572-2.364H2.287V3.364H18.3v6.855s1.372-.827 2.287-.355v-6.5A2.335 2.335 0 0018.3 1H7.091z" />
        <path d="M10.941 24.841a2.026 2.026 0 01-1.949-2.071c-.008-.21.034-.418.122-.609.13-.514.336-1.007.609-1.462l.487-1.1c0-.122.122-.244.122-.487 0-.122.122-.244.244-.365a.422.422 0 00.122-.244c0-.016.002-.032.008-.047s.015-.029.027-.04a.107.107 0 01.04-.026.107.107 0 01.047-.009l7.553-6.578a3.722 3.722 0 012.436-.853c.434-.01.86.118 1.218.365.422.204.797.495 1.1.853l.122.122a3.244 3.244 0 01.731 2.436 3.458 3.458 0 01-1.1 2.315l-7.553 6.582-.244.244c.122-.122 0-.122 0-.122-.016 0-.032.002-.047.008s-.029.015-.04.027a.123.123 0 00-.027.04.145.145 0 00-.008.047h-.244c-.783.158-1.556.361-2.315.609l-.853.244h-.122a.92.92 0 01-.486.121zm1.705-4.63c0 .122-.122.244-.122.365l-.487 1.1c-.122.244-.122.365-.244.487a20.143 20.143 0 012.071-.487l7.428-6.459c.19-.154.319-.37.365-.609 0-.122 0-.244-.244-.731-.122-.122-.122-.122-.244-.122h-.117c-.122 0-.244-.122-.365-.122a1.728 1.728 0 00-.731.244l-7.31 6.334zm-5.77-1.059a.9.9 0 01-.9-.448l-1.79-2.387a1.05 1.05 0 01-.25-.894 1.067 1.067 0 01.55-.747 1.05 1.05 0 01.894-.25 1.067 1.067 0 01.747.55l.746 1.044 2.238-2.834a1.172 1.172 0 01.79-.416 1.16 1.16 0 01.853.264 1.168 1.168 0 01.151 1.643l-3.283 4.177c-.204.187-.47.293-.746.298zm-1.985-6.707a2.103 2.103 0 01-.9-.3 1.172 1.172 0 010-1.641L8.765 5.88a1.16 1.16 0 111.641 1.641l-4.769 4.626c-.152.298-.452.298-.746.298z" />
        <path d="M9.664 12.445a2.103 2.103 0 01-.9-.3L4.145 7.523a1.16 1.16 0 111.641-1.641l4.774 4.624a1.171 1.171 0 010 1.641 1.095 1.095 0 01-.896.298zm8.229-6.594h-7.011v2.387h7.011V5.85z" />
        <path d="M17.893 9.086h-7.011v2.387h7.011V9.086z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v23.841H0z" transform="translate(0 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssessmentSVG;
