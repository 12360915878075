import * as React from "react";

const CampaignWhite = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.815">
      <g data-name="Group 12035" fill={props.fill || "#fff"}>
        <path
          data-name="Path 11415"
          d="M9.977 13.384V3.128a11.416 11.416 0 015.012-1.334 10.457 10.457 0 013.185.46V5.8A3.355 3.355 0 0120 6.761V1.334A5.148 5.148 0 0018.173.46 10.671 10.671 0 0014.988 0a8.7 8.7 0 00-5.012 1.334A8.7 8.7 0 004.965 0 8.644 8.644 0 000 1.334V14.4a.5.5 0 00.468.46c.094 0 .141-.046.234-.046a10.876 10.876 0 014.309-.966 8.7 8.7 0 015.012 1.334A6.911 6.911 0 0111.9 14.4a4.643 4.643 0 01-.843-1.472 3.787 3.787 0 00-1.08.456z"
        />
        <path
          data-name="Path 11416"
          d="M19.21 11.508a3.607 3.607 0 00-.847-.734.428.428 0 00-.226-.113 11.173 11.173 0 00-1.863-.677 10.642 10.642 0 01-1.411-.508.708.708 0 01-.339-.621.8.8 0 01.339-.677 1.562 1.562 0 01.9-.282 1.821 1.821 0 011.693 1.242l.96-.282.9-.282a3.009 3.009 0 00-.9-1.3 3.653 3.653 0 00-2.032-.734v-.959h-1.352v.96a3.322 3.322 0 00-2.2.9 2.489 2.489 0 00-.677 1.693 1.924 1.924 0 00.4 1.242 3.7 3.7 0 001.016.847 10 10 0 001.75.621 5.626 5.626 0 011.581.564.8.8 0 01.339.677v.226a1.015 1.015 0 01-.339.508 1.6 1.6 0 01-1.129.282 1.714 1.714 0 01-1.806-1.016c-.056-.113-.056-.226-.113-.339l-2.145.4c0 .113.056.169.113.282a3.048 3.048 0 001.863 1.863 3.411 3.411 0 001.355.282v1.242h1.355v-1.3a2.86 2.86 0 00.734-.113 3.389 3.389 0 001.693-.79 2.412 2.412 0 00.847-1.863 2.557 2.557 0 00-.459-1.241z"
        />
      </g>
    </svg>
  );
};

export default CampaignWhite;
