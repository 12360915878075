import * as Sentry from "@sentry/nextjs";
import { SpanStatus } from "@sentry/tracing";
import cookies from "js-cookie";
import { match } from "path-to-regexp";

export const setSentryUser = ({
  loginStatus,
  id = cookies.get("academy-session"),
}) => {
  try {
    const { email, name, token } = loginStatus;
    Sentry.setUser({
      email,
      name,
      id,
    });
    Sentry.setContext("user token", {
      token,
    });
  } catch (error) {
    console.error("Sentry set user error", error);
  }
};

export const addSpan = ({ data, error }) => {
  const transaction = Sentry.getCurrentHub().getScope().getTransaction();
  if (transaction) {
    const span = transaction.startChild({ ...data });
    if (error) {
      span.setStatus(SpanStatus.InternalError);
    } else {
      span.setStatus(SpanStatus.Ok);
    }
    span.finish();
  } else {
    console.info("[Sentry] No transaction found");
  }
};

const sentryURLList = [
  "/api/v1/catalog/course/:courseId",
  "/api/v1/learning-system/course/:courseId/enroll_status",
  "/api/v1/like/course(.*)",
  "/api/v1/catalog/course/caption/language(.*)",
  "/api/v1/learning-system/course/syllabus(.*)",
  "/api/v1/user/billing",
  "/api/v1/order-system/order",
  "/api/v1/tms-adapter/order-system/order",
];

const isURLInList = (url, list) => {
  return list.some((targetUrl) => {
    const matchPath = match(targetUrl, { decode: decodeURIComponent });
    return matchPath(url);
  });
};

const getURLMatchedIndex = (url, list) => {
  return list.findIndex((targetUrl) => {
    const matchPath = match(targetUrl, { decode: decodeURIComponent });
    return matchPath(url);
  });
};

export const finishTransaction = ({ config, response, error }) => {
  try {
    if (config) {
      const { sentrySpan, sentryTransaction } = config;
      if (sentrySpan) {
        sentrySpan.finish();
      }
      if (typeof sentryTransaction?.finish === "function") {
        if (response) {
          const child = sentryTransaction.startChild({
            op: "http",
          });
          child.setData("headers", JSON.stringify(response.headers));
          child.finish();
          sentryTransaction.setData(
            "headers",
            JSON.stringify(response.headers),
          );
        }
        if (error) {
          // timeout no response
          sentryTransaction.setStatus(
            String(error.response?.status || error.code),
          );
        } else {
          sentryTransaction.setStatus("ok");
        }
        sentryTransaction.finish();
      }
    }
  } catch (sentryError) {
    console.error("finishSentry", sentryError);
  }
};

export const getTransactionInList = ({ url }) => {
  try {
    if (isURLInList(url, sentryURLList)) {
      const matchedIndex = getURLMatchedIndex(url, sentryURLList);
      const name = sentryURLList[matchedIndex];
      return Sentry.startTransaction({
        op: "http",
        name,
        tags: {
          url,
          op: "monitor_api_speed",
        },
      });
    }
  } catch (error) {
    console.error("getTransactionInList", error);
  }
  return null;
};

export const getSentrySpanInList = ({ url, method }) => {
  try {
    if (isURLInList(url, sentryURLList)) {
      const parent = Sentry.getCurrentHub().getScope().getSpan();
      if (parent) {
        return parent.startChild({
          data: { type: "axios" },
          description: `${method} ${url}`,
          op: "http.client",
        });
      }
    }
  } catch (error) {
    console.error("getSentrySpanInList", error);
  }
  return null;
};
