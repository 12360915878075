import * as React from "react";

const EmptySVG = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2.223 11.246l-1.177 1.617a.716.716 0 001.158.843l1.186-1.63a5.531 5.531 0 002.61.651 5.574 5.574 0 005.568-5.568c0-1.565-.65-2.98-1.693-3.993l1.477-2.029a.716.716 0 00-1.158-.842L8.73 2.308a5.53 5.53 0 00-2.73-.717A5.574 5.574 0 00.433 7.159c0 1.614.69 3.069 1.79 4.087zm7.913-4.087A4.141 4.141 0 016 11.296c-.628 0-1.22-.145-1.754-.396L9.019 4.34a4.116 4.116 0 011.117 2.818zM6 3.023c.676 0 1.313.166 1.877.455l-4.805 6.6A4.123 4.123 0 011.863 7.16 4.141 4.141 0 016 3.023z"
        fill={props.fill || "#9FA0A0"}
      />
    </svg>
  );
};

export default EmptySVG;
