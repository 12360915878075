import appJson from "@configs/app.json";
import customSCM from "@configs/cust.config.js";
import defaultSCM from "@configs/scm.config.js";
import getConfig from "next/config";

const merge = require("deepmerge");

const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;

const { publicRuntimeConfig } = getConfig();
export const { ANDROID_DOWNLOAD_LINK } = publicRuntimeConfig;
export const API_BASE_URL = publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL;
export const { ENABLE_SENTRY } = publicRuntimeConfig;
export const { SENTRY_TRACING_ORIGINS } = publicRuntimeConfig;
export const { SENTRY_ENABLE_TRACING_VIDEO } = publicRuntimeConfig;
export const { SENTRY_TRACING_VIDEO_NAME } = publicRuntimeConfig;
export const { SENTRY_TRACING_RATE } = publicRuntimeConfig;
export const { IOS_DOWNLOAD_LINK } = publicRuntimeConfig;
export const { IOS_INSTALL_GUIDE } = publicRuntimeConfig;
export const { ANDROID_INSTALL_GUIDE } = publicRuntimeConfig;
export const { NEXT_PUBLIC_SENTRY_DSN } = publicRuntimeConfig;
export const { NEXT_PUBLIC_DEBUG } = publicRuntimeConfig;
export const REACT_APP_ENV = publicRuntimeConfig.NEXT_PUBLIC_REACT_APP_ENV;
export const REACT_APP_FB_APP_ID =
  publicRuntimeConfig.NEXT_PUBLIC_REACT_APP_FB_APP_ID;
export const REACT_APP_GOOGLE_APP_ID =
  publicRuntimeConfig.NEXT_PUBLIC_REACT_APP_GOOGLE_APP_ID;
export const SCM_CONFIG = publicRuntimeConfig.NEXT_PUBLIC_SCM_CONFIG;
export const APP_LOGO = publicRuntimeConfig.APP_LOGO_URL;
export const { USER_GUIDE_URL_EN } = publicRuntimeConfig;
export const { USER_GUIDE_URL_ZH_HANS } = publicRuntimeConfig;
export const { USER_GUIDE_URL_ZH_HANT } = publicRuntimeConfig;
export const { APP_DEEPLINK_PREFIX } = publicRuntimeConfig;
export const { NEXT_PUBLIC_SSO_DOMAIN } = publicRuntimeConfig;
export const { NEXT_PUBLIC_SSO_CALLBACK } = publicRuntimeConfig;
export const CUSTOM_HEADER_LOGO =
  publicRuntimeConfig.NEXT_PUBLIC_CUSTOM_HEADER_LOGO;
export const CUSTOM_HEADER_APP_NAME =
  publicRuntimeConfig.NEXT_PUBLIC_CUSTOM_HEADER_APP_NAME;
export const { NEXT_PUBLIC_HEAD_TITLE } = publicRuntimeConfig;
export const { NEXT_PUBLIC_DEFAULT_COURSE_COVER } = publicRuntimeConfig;

export { appJson };
export const WEBSOCKET_URL = `${API_BASE_URL}/api/v1/ws-hub`;
export const WEBSOCKET_LEARNING_URL = `${API_BASE_URL}/api/v1/learning-system/ws`;
export const scm = merge(
  merge(defaultSCM, customSCM, {
    arrayMerge: overwriteMerge,
  }),
  JSON.parse(SCM_CONFIG || "{}"),
  {
    arrayMerge: overwriteMerge,
  },
);
export const displayUnauthorized =
  scm.user.login.display_unauthorized[0] !== "";
export const appRootPath = displayUnauthorized ? "/" : "/login";
export const forceLanguage = scm.system.forceLang;
export const localeFallbackLanguage = scm.system.fallbackLang;
