const merge = require("deepmerge");

const customSCM = require("./cust.config");

const defaultSCM = require("./scm.config");

const publicRuntimeConfig = require("./publicRuntimeConfig");

const overwriteMerge = (_destinationArray, sourceArray) => sourceArray;

const {
  API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL,
  SCM_CONFIG = process.env.NEXT_PUBLIC_SCM_CONFIG,
} = publicRuntimeConfig;

const INTRA_API_BASE_URL =
  typeof process.env.NEXT_SERVER_INTRA_API_BASE_URL !== "undefined" &&
  process.env.NEXT_SERVER_INTRA_API_BASE_URL !== ""
    ? process.env.NEXT_SERVER_INTRA_API_BASE_URL
    : API_BASE_URL;

const scm = merge(
  merge(defaultSCM, customSCM, {
    arrayMerge: overwriteMerge,
  }),
  JSON.parse(SCM_CONFIG || "{}"),
  {
    arrayMerge: overwriteMerge,
  },
);
const displayUnauthorized = scm.user.login.display_unauthorized[0] !== "";
const forceLanguage = scm.system.forceLang;
const localeFallbackLanguage = scm.system.fallbackLang;
const appRootPath = displayUnauthorized ? "/" : "/login";

module.exports = {
  appRootPath,
  displayUnauthorized,
  forceLanguage,
  INTRA_API_BASE_URL,
  localeFallbackLanguage,
  scm,
};
