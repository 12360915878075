// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  API_BASE_URL,
  appJson,
  ENABLE_SENTRY,
  NEXT_PUBLIC_DEBUG,
  NEXT_PUBLIC_SENTRY_DSN,
  REACT_APP_ENV as env,
  SENTRY_ENABLE_TRACING_VIDEO,
  SENTRY_TRACING_ORIGINS as origins,
  SENTRY_TRACING_RATE,
  SENTRY_TRACING_VIDEO_NAME,
} from "@components/AppEnv";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

import RangeableRequestTracing from "./src/utils/rangeable-request-tracing/index.ts";

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  NEXT_PUBLIC_SENTRY_DSN;

const sentryOrigins = typeof origins !== "undefined" ? JSON.parse(origins) : [];

const integrations = [
  new BrowserTracing({
    tracePropagationTargets: [...["localhost", /^\//], ...sentryOrigins],
    shouldCreateSpanForRequest: (url) => {
      return !url.match(/\/health_check\/?$/);
    },
  }),
  new OfflineIntegration({
    // limit how many events will be localized saved. Defaults to 30.
    // maxStoredEvents: number;
  }),
];

const tracingVideoName =
  typeof SENTRY_TRACING_VIDEO_NAME !== "undefined"
    ? SENTRY_TRACING_VIDEO_NAME || "video-tracing"
    : "video-tracing";

if (SENTRY_ENABLE_TRACING_VIDEO) {
  integrations.push(
    new RangeableRequestTracing({
      forceRestart: false,
      transactionName: tracingVideoName,
      urlMatch: /[^\\]*\.(ts|m3u8)$/,
      tracingOrigins: sentryOrigins,
    }),
  );
}

const tracesSampleRate =
  typeof SENTRY_TRACING_RATE !== "undefined"
    ? parseFloat(SENTRY_TRACING_RATE)
    : 1.0;

Sentry.init({
  ignoreErrors: ["Non-Error promise rejection captured"],
  dsn: SENTRY_DSN,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: env,
  release: appJson.version[env],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate,
  integrations,
  beforeSend(event) {
    return ENABLE_SENTRY ? event : null;
  },
});

Sentry.setTag("isDebug", NEXT_PUBLIC_DEBUG);
Sentry.setTag("source", "page");
Sentry.setTag("api", API_BASE_URL);
