import * as React from "react";

const HtmlWhite = (props) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 26 26"
      width="20"
      height="20">
      <g transform="translate(1 1)">
        <g id="ic_1580971344923">
          <path
            d="M20.8 3.2H3c-.6 0-1 .4-1 1v15.6c0 .6.4 1 1 1h17.8c.6 0 1-.4 1-1V4.2c0-.6-.5-1-1-1zm-1 2V7H4V5.2h15.8zM4 18.8V9h15.8v9.7H4z"
            fill={props.fill || "#fff"}
          />
          <path
            d="M9 11.3c-.4-.4-1-.4-1.4 0l-2 2c-.2.2-.3.4-.3.7s.1.5.3.7l2 2c.2.2.5.3.7.3s.6 0 .7-.2c.4-.4.4-1 0-1.4L7.7 14 9 12.7c.4-.4.4-1 0-1.4zm7.1 0c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L16 14l-1.3 1.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-2-2zm-3.4-.5c-.5-.1-1.1.2-1.2.7L10.3 16c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l1.2-4.6c.1-.4-.2-1-.8-1.1z"
            fill={props.fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default HtmlWhite;
