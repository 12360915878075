import { scm } from "@components/AppEnv";
import { GetAuthTokenConfig } from "@utils/api-utils";
import clientAPI from "@utils/axios/client-api";
import nodeAPI from "@utils/axios/node-api";
import map from "lodash/map";
import qs from "querystringify";

/**
 * showCategoryList
 * @api {get} /api/v1/dashboard/weekly
 * @apiDescription get dashboard weekly
 */

const useTmsAdapter = scm?.explore?.use_tms_adapter;
const showCategoryList = ({
  categoryId,
  sortKey,
  pageNum = 0,
  pageSize = 7,
  language,
}) => {
  if (sortKey === "weekly_ranking") {
    const query = qs.stringify({
      pageNum,
      pageSize,
      sortOrder: -1,
      language,
    });
    return clientAPI
      .get(
        `/api/v1/${
          useTmsAdapter ? "tms-adapter/" : ""
        }dashboard/weekly?${query}`,
      )
      .then((res) => res.data);
  }
  const query = qs.stringify({
    categoryId,
    sortKey,
    pageSize,
    pageNum,
    sortOrder: -1,
    language,
  });
  return clientAPI
    .get(
      `/api/v1/${useTmsAdapter ? "tms-adapter/" : ""}catalog/course?${query}`,
    )
    .then((res) => res.data);
};

const getCampaignList = ({
  pageNum = 0,
  pageSize = 9999,
  sortKey = "create_time",
  sortOrder = -1,
} = {}) => {
  const query = qs.stringify({
    pageNum,
    pageSize,
    sortKey,
    sortOrder,
  });
  return clientAPI
    .get(`/api/v1/catalog/campaign?${query}`)
    .then((res) => res.data);
};

const getAutoCompleteList = (keyword) => {
  const query = qs.stringify({ title: keyword });
  return clientAPI
    .get(`/api/v1/search/auto_complete?${query}`)
    .then((res) => res.data);
};

const getTrendingList = ({ language }) => {
  const query = qs.stringify({
    language,
  });
  return clientAPI
    .get(`/api/v1/catalog/course/trending?${query}`)
    .then((res) => res.data);
};

const getSearchResult = ({ keyword, sortBy, pageNum = 0 }) => {
  const transferToSortOrder = (sortKey) => {
    switch (sortKey) {
      case "relevance":
        return "default";
      case "mostRecent":
        return "publish_time";
      case "popularity":
        return "view_count";
      default:
        return "default";
    }
  };
  const query = qs.stringify({
    sortOrder: -1,
    keyword,
    pageNum,
    pageSize: 7,
    sortKey: transferToSortOrder(sortBy),
  });
  return clientAPI
    .get(
      `/api/v1/${
        useTmsAdapter ? "tms-adapter/" : ""
      }search/catalog/course?${query}`,
    )
    .then((res) => res.data);
};

/**
 * @api {get} /api/v1/catalog/course/tag/${tag}
 * @apiDescription get Search Results By Tag
 */
const getSearchResultsByTag = (tag) =>
  clientAPI.get(`/api/v1/catalog/course/tag/${tag}`).then((res) => res.data);

const getPublishedCourseInfo = (courseId, type) =>
  clientAPI.get(`/api/v1/catalog/${type}/${courseId}`).then((res) => res.data);

const getCourseInfoByServer = ({ cardId, previewType, loginStatus }) =>
  nodeAPI
    .get(
      `/api/v1/catalog/${previewType}/${cardId}`,
      GetAuthTokenConfig(loginStatus),
    )
    .then((res) => res.data);

const getLikesInfo = (itemType, itemId) =>
  clientAPI
    .get(`/api/v1/like/${itemType}?itemId=${itemId}`)
    .then((res) => res.data);

const postLikesInfo = (itemType, itemId, status) =>
  clientAPI
    .post(`/api/v1/like/${itemType}?status=${status}&itemId=${itemId}`)
    .then((res) => res.data);

const postShareInfo = (id, type, selectedArray, message) => {
  const params = new URLSearchParams();
  params.append("id", id);
  params.append("type", type);
  params.append("message", message);
  map(selectedArray, (item) => {
    params.append("emails", item.text.email);
  });
  const config = {
    params,
  };
  return clientAPI
    .post("/api/v1/catalog/share/", null, config)
    .then((res) => res.data);
};

const postSearchHistory = (keyword) =>
  clientAPI
    .post(`/api/v1/logging/search?content=${encodeURI(keyword)}`)
    .then((res) => res.data);

const getSearchHistory = () =>
  clientAPI.get("/api/v1/logging/search").then((res) => res.data);

const deleteSearchHistory = (keyword) =>
  clientAPI
    .delete(`/api/v1/logging/search?content=${encodeURI(keyword)}`)
    .then((response) => response.data);

export {
  deleteSearchHistory,
  getAutoCompleteList,
  getCampaignList,
  getCourseInfoByServer,
  getLikesInfo,
  getPublishedCourseInfo,
  getSearchHistory,
  getSearchResult,
  getSearchResultsByTag,
  getTrendingList,
  postLikesInfo,
  postSearchHistory,
  postShareInfo,
  showCategoryList,
};
