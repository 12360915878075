import Image from "next/image";
import React, { useEffect, useState } from "react";

const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const ImageWithFallback = (props) => {
  const { src, fallbackSrc, width, height, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...rest}
      alt="image"
      sizes="(max-width: 1350px) 50vw, 100vw"
      src={imgSrc}
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(width, height),
      )}`}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
      priority
    />
  );
};

export default ImageWithFallback;
