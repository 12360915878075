import * as React from "react";

const LeaderboardSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" key="LeaderboardSVG-icon-path-1" />
      <path
        fill={props.fill || "#727171"}
        key="LeaderboardSVG-icon-path-2"
        d="M16 11V3H8v6H2v12h20V11h-6zm-6-6h4v14h-4V5zm-6 6h4v8H4v-8zm16 8h-4v-6h4v6z"
      />
    </svg>
  );
};

export default LeaderboardSVG;
