import dynamic from "next/dynamic";

const JudgementBrowsersTypeModal = dynamic(
  () => import("./JudgementBrowsersTypeModal"),
  {
    ssr: false,
  },
);

export default JudgementBrowsersTypeModal;
