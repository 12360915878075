import Colors from "@constants/colors";
import { createTheme } from "@material-ui/core/styles";

import { fontFamily } from "./fontFamily";

export const themeConfig = {
  palette: {
    text: {
      primary: Colors.gray500,
      secondary: Colors.gray500,
    },
    primary: {
      main: Colors.blue500,
      contrastText: "#fff",
    },
    secondary: {
      main: Colors.gray500,
      // contrastText: "#fff",
    },
    action: {
      activatedOpacity: 0.16,
      disabled: Colors.gray100,
    },
  },
  typography: {
    fontFamily,
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        color: Colors.gray300,
      },
    },
    MuiAppBar: {
      root: {
        height: 68,
        borderBottom: "1px solid #EFEFEF",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "68px !important",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.161)",
      },
      elevation24: {
        boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.161)",
      },
    },
    MuiInputBase: {
      input: {
        color: Colors.gray500,
        "-webkit-text-fill-color": Colors.gray500,
      },
    },
    MuiDialogActions: {
      root: {
        padding: "12px 52px",
      },
    },
    MuiDialogTitle: {
      root: {
        color: Colors.gray500,
        padding: "24px 52px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "24px",
        maxHeight: "450px",
      },
      dividers: {
        padding: "24px 52px",
      },
    },
    MuiDialog: {
      root: {
        zIndex: "1500 !important",
      },
      container: {
        minHeight: 624,
      },
      paperWidthSm: {
        maxWidth: "600px",
      },
      paperWidthMd: {
        maxWidth: "1020px",
      },
    },
    MuiCheckbox: {
      root: {
        color: Colors.gray500,
        height: 24,
        width: 24,
        padding: 0,
        "&$checked": {
          color: Colors.blue500,
        },
      },
      indeterminate: {
        "&$disabled": {
          color: Colors.gray100,
        },
      },
      colorPrimary: {
        "&$checked": {
          "&:hover": {
            backgroundColor: "rgba(0, 173, 239, 0.92)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      colorSecondary: {
        "&$checked": {
          color: "#00adef",
        },
      },
    },
    MuiChip: {
      outlined: {
        backgroundColor: "rgba(202, 234, 254, 0.6)",
        margin: 5,
        border: "none",
      },
      label: {
        maxWidth: 312,
      },
      deleteIcon: {
        width: 16,
        height: 16,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
        position: "absolute",
        right: 0,
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#00adef",
      },
      root: {
        fontFamily,
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 4,
        lineHeight: "16px",
      },
      label: {
        textDecoration: "none",
      },
      text: {
        height: 36,
        padding: "10px 8px",
        "&$disabled": {
          boxShadow: "none",
        },
      },
      outlined: {
        height: 36,
        padding: "10px 16px",

        "&$disabled": {
          boxShadow: "none",
          border: `1px solid ${Colors.gray100}`,
        },
      },
      containedPrimary: {
        padding: "10px 16px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: `${Colors}`,
        },
        "&:focus": {
          boxShadow: "none",
          backgroundColor: "rgba(0, 173, 239, 0.24)",
        },
        "&$focusVisible": {
          boxShadow: "none",
          backgroundColor: "rgba(0, 173, 239, 0.24)",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: "rgba(0, 173, 239, 0.32)",
        },
        "&$disabled": {
          boxShadow: "none",
          color: "#EFEFEF",
          backgroundColor: Colors.gray100,
        },
      },
    },
    MuiSwitch: {
      root: {
        color: "#fafafa",
      },
      colorSecondary: {
        "&$checked": {
          color: "#00adef",
        },
        "&$checked + $track": {
          backgroundColor: "#00adef",
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        "&$active": {
          color: Colors.gray700,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontWeight: 400,
        padding: "12px 15px",
        fontSize: 14,
        lineHeight: "18px",
        color: Colors.gray500,
        backgroundColor: "white",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: 4,
      },
    },
    MuiSnackbarContent: {
      root: {
        padding: "6px 16px !important",
      },
      message: {
        display: "flex",
        alignItems: "center",
        minWidth: 258,
        maxWidth: 514,
        wordBreak: "break-word",
        lineHeight: "20px",
      },
      action: {
        marginRight: 8,
        marginLeft: 8,
      },
    },
    MuiSnackbar: {
      anchorOriginBottomLeft: {
        zIndex: "10000 !important",
      },
    },
    MuiDrawer: {
      modal: {
        MuiBackdrop: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
      },
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 12,
      },
    },
    MuiInput: {
      root: {
        // this focus will affect all input
        // "&$focused": {
        //   border: "1px solid #00adef",
        //   borderRadius: "3px !important",
        //   backgroundColor: "#f1f1f1",
        // },
      },
    },
    MuiSelect: {
      root: {
        borderRadius: 3,
        paddingLeft: 5,
      },
    },
    MuiTreeItem: {
      root: {
        "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
          backgroundColor: "transparent",
          "&:before": {
            backgroundColor: "transparent",
          },
        },
      },
      label: {
        "&:hover": {
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.04) !important",
          "&:before": {
            content: "''",
            position: "absolute",
            right: 0,
            top: 0,
            left: "-100%",
            width: "inherit",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
          },
        },
      },
    },
  },
};

export default createTheme(themeConfig);
