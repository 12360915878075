import * as React from "react";

function WebLinkSVG(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.027 13.826L7.99349 16.887C7.99349 16.887 7.99349 16.8871 7.99342 16.8871C7.99342 16.8871 7.99342 16.8871 7.99335 16.8871C6.73889 18.153 4.6976 18.1531 3.443 16.8871C2.83522 16.2739 2.50058 15.4585 2.50058 14.5914C2.50058 13.7243 2.83522 12.9091 3.44278 12.2958C3.44285 12.2958 3.44292 12.2957 3.443 12.2956L6.47654 9.23463C6.89534 8.81198 6.89534 8.12671 6.47647 7.70414C6.05767 7.28156 5.37853 7.28156 4.95966 7.70414L1.92612 10.7651C1.9259 10.7653 1.92569 10.7656 1.92547 10.7658C0.913054 11.7878 0.355469 13.1464 0.355469 14.5914C0.355469 16.0367 0.913269 17.3956 1.92619 18.4176C2.97172 19.4725 4.34494 20 5.71824 20C7.09154 20 8.46484 19.4725 9.51022 18.4176V18.4176C9.51029 18.4176 9.5103 18.4175 9.5103 18.4175L12.5438 15.3565C12.9626 14.9339 12.9626 14.2487 12.5438 13.826C12.125 13.4034 11.446 13.4034 11.027 13.826Z"
        fill={props.fill || "#C9CACA"}
      />
      <path
        d="M20.3553 5.40856C20.3553 3.96321 19.7974 2.60436 18.7845 1.5823C16.6935 -0.52747 13.2913 -0.527397 11.2004 1.5823C11.2004 1.58245 11.2002 1.58252 11.2002 1.58266L8.16669 4.64342C7.74782 5.066 7.74782 5.75134 8.16669 6.17391C8.3762 6.38531 8.65063 6.49093 8.92513 6.49093C9.19956 6.49093 9.47413 6.38524 9.6835 6.17391L12.717 3.11315C12.717 3.11301 12.7172 3.11293 12.7173 3.11279C13.9718 1.84701 16.0131 1.84694 17.2677 3.11279C17.8754 3.72605 18.2102 4.54141 18.2102 5.40856C18.2102 6.27564 17.8755 7.09085 17.2679 7.70411C17.2678 7.70419 17.2677 7.70426 17.2677 7.70433L14.2341 10.7653C13.8153 11.1879 13.8153 11.8732 14.2342 12.2958C14.4436 12.5071 14.7181 12.6128 14.9926 12.6128C15.2671 12.6128 15.5416 12.5071 15.751 12.2958L18.7846 9.23482C18.7848 9.2346 18.785 9.23431 18.7852 9.2341C19.7976 8.21218 20.3553 6.85355 20.3553 5.40856Z"
        fill={props.fill || "#C9CACA"}
      />
      <path
        d="M6.56317 13.8258C6.77261 14.0371 7.04711 14.1428 7.32154 14.1428C7.59604 14.1428 7.87055 14.0371 8.07998 13.8258L14.1472 7.70386C14.5661 7.28129 14.5661 6.59602 14.1472 6.17338C13.7284 5.7508 13.0493 5.7508 12.6304 6.17338L6.56317 12.2953C6.14431 12.718 6.14431 13.4032 6.56317 13.8258Z"
        fill={props.fill || "#C9CACA"}
      />
    </svg>
  );
}

export default WebLinkSVG;
