import { API_BASE_URL } from "@components/AppEnv";
import cacheAPIList from "@configs/cache-api-list";
import {
  convertToBearerToken,
  getConfigTimeout,
  parseError,
} from "@utils/api-utils";
import { getTransactionInList } from "@utils/sentry-utils";
import Axios from "axios";
import { setupCache } from "axios-cache-adapter";
import cookie from "js-cookie";

const cache = setupCache({
  // readHeaders: true, // Tell adapter to attempt using response headers
  maxAge: 300 * 1000, // 300s
  exclude: {
    filter: (req) => {
      if (req.method === "get") {
        const urlWithoutQuery =
          req.url.substring(0, req.url.indexOf("?")) || req.url;
        const doNotCache = !(cacheAPIList[urlWithoutQuery] > 0);
        return doNotCache; // true === can't be cache
      }
      return true;
    },
    query: false,
  },
  // debug: true,
});

let addedOnResponseFuncs = [];
let addedOnDownloadFuncs = [];
let addedOnUploadFuncs = [];

const instance = Axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
});
export default instance;

// Add timeout
instance.interceptors.request.use((config) => {
  const { method, timeout, url } = config;
  // get method timeout 60s. Get API should response in 3s as spec required.
  // or if request timeout set to less than 0 means no timeout.
  const baseConfig = {
    ...config,
    timeout: getConfigTimeout({ timeout, method }),
    // sentrySpan: getSentrySpanInList(config),
    sentryTransaction: getTransactionInList(config),
  };
  const urlWithoutQuery = url.substring(0, url.indexOf("?")) || url;
  const doNotCache = !(cacheAPIList[urlWithoutQuery] > 0);
  const token = cookie.get("token");
  if (token) {
    baseConfig.headers = {
      ...baseConfig.headers,
      Authorization: convertToBearerToken(token),
    };
  }
  if (method === "get" && !doNotCache) {
    baseConfig.cache = {
      maxAge: cacheAPIList[urlWithoutQuery],
    };
  }
  return baseConfig;
});
instance.interceptors.response.use(undefined, (error) => {
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ ...parseError(error) });
});

export const clearCache = () => cache.store.clear();
export const addOnResponseFunc = (func) => {
  if (typeof func === "function") {
    addedOnResponseFuncs.push(
      instance.interceptors.response.use(
        (response) => {
          func(response);
          return response;
        },
        (error) => {
          func(error);
          return Promise.reject(error);
        },
      ),
    );
  }
};

export const addOnDownloadProgressFunc = (func) => {
  if (typeof func === "function") {
    addedOnDownloadFuncs.push(
      instance.interceptors.request.use((config) => ({
        ...config,
        onDownloadProgress: (event) => {
          func(event);
        },
      })),
    );
  }
};

export const addOnUploadProgressFunc = (func) => {
  if (typeof func === "function") {
    addedOnUploadFuncs.push(
      instance.interceptors.request.use((config) => ({
        ...config,
        onUploadProgress: (event) => {
          func(event);
        },
      })),
    );
  }
};

// NOTE: removeAllOnResponseFuncs call on _app.js un-mount;
export const removeAllOnResponseFuncs = () => {
  addedOnResponseFuncs.forEach((func) => {
    instance.interceptors.response.eject(func);
  });
  addedOnResponseFuncs = [];
};

export const removeAllOnDownloadProgressFuncs = () => {
  addedOnDownloadFuncs.forEach((func) => {
    instance.interceptors.request.eject(func);
  });
  addedOnDownloadFuncs = [];
};

export const removeAllOnUploadProgressFuncs = () => {
  addedOnUploadFuncs.forEach((func) => {
    instance.interceptors.request.eject(func);
  });
  addedOnUploadFuncs = [];
};
