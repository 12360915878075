import "./mui-overwrite.scss";
import "@styles/globals.scss";
import "@/public/fonts/material-icon.css";
import "@/public/fonts/roboto.css"; // default font
import "@utils/polyfill";

import ComponentWrapper from "@components/ComponentWrapper";
import Scaffold from "@components/Scaffold";
import theme from "@constants/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { getUserPrivacy, getUserPrivilege } from "@utils/server/api-point";
import dynamic from "next/dynamic";
import nookies from "nookies";
import React, { Fragment, useEffect } from "react";

const ClientAxiosSetting = dynamic(
  () => import("@components/ClientAxiosSetting"),
  { ssr: false },
);

const UploadControlCenter = dynamic(
  () => import("@page-components/UploadControlCenter"),
  {
    ssr: false,
  },
);

const MyApp = (props) => {
  const { Component, pageProps = {}, pageQuery } = props;
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Fragment>
      <Scaffold pageProps={pageProps} />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        {/* Jingjie Note: use CssBaseline will need to fix all style */}
        {/* <CssBaseline /> */}
        <ComponentWrapper data={pageQuery} {...pageProps}>
          <ClientAxiosSetting />
          <UploadControlCenter>
            <Component data={pageQuery} {...pageProps} />
          </UploadControlCenter>
        </ComponentWrapper>
      </ThemeProvider>
    </Fragment>
  );
};

MyApp.getInitialProps = async (props) => {
  const { ctx, req } = props;
  if (ctx.pathname === "/404" || ctx.pathname === "/500") {
    const cookies = nookies.get(ctx);
    const loginStatus = {
      token: cookies.token || "",
      email: cookies.email || "",
      avatar: cookies.avatar || "",
      loginType: cookies.loginType || "",
      name: cookies.name || "",
    };
    const { data: privilegeData = [] }: { data?: any; error?: any } =
      loginStatus.token && req ? await getUserPrivilege(loginStatus) : {};
    const { data: userPrivacyData = {} } =
      loginStatus.token && req ? await getUserPrivacy(loginStatus) : {};
    const userPrivacy = userPrivacyData || {};
    const userPrivilege = privilegeData || [];
    return {
      pageQuery: {
        loginStatus,
        userPrivilege,
        userPrivacy: {
          activity: userPrivacy.activity === "true",
          enrollment: userPrivacy.enrollment === "true",
          information: userPrivacy.information === "true",
          location: userPrivacy.location === "true",
        },
      },
    };
  }

  return {}; // error page ctx.query is {}
};

export default MyApp;
