export const convertToBearerToken = (token) => `Bearer ${token}`;

export const getConfigTimeout = ({ timeout, method }) => {
  // get method timeout 60s. Get API should response in 3s as spec required.
  // or if request timeout set to less than 0 means no timeout.
  if (timeout < 0) {
    return 0;
  }
  if (method === "get") {
    return 60000;
  }
  return 0;
};

export const GetAuthTokenConfig = (loginStatus) => {
  const authToken = `Bearer ${loginStatus.token}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (loginStatus.token) {
    config.headers = {
      ...config.headers,
      Authorization: authToken,
    };
  }

  return config;
};

export default GetAuthTokenConfig;

export const parseError = (error = {}) => {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      const { response } = error;
      const { data } = response;
      return {
        timestamp: new Date().toISOString(),
        baseURL: error.config.baseURL,
        pathname: error.config.url,
        method: error.config.method,
        message:
          typeof data === "object"
            ? response.data.error?.message || error.message
            : error.message,
        status: response.status,
        code:
          typeof data === "object"
            ? response.data.error?.code
            : response.status,
        reason:
          typeof data === "object"
            ? response.data.error?.reason
            : response.message,
      };
    }
    if (error.request && error.config) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        timestamp: new Date().toISOString(),
        baseURL: error.config.baseURL,
        pathname: error.config.url,
        method: error.config.method,
        message: error.message,
        status: 503, // 503 Service Unavailable
      };
    }
  } catch (err) {
    console.error("[ParseError] exception", err);
    return { ...error };
  }
  // Something happened in setting up the request that triggered an Error
  const result = {
    error: {
      status: 500, // unknown
      timestamp: new Date().toISOString(),
    },
  };
  console.error("[Error] parseError unknown", JSON.stringify(result));
  return result;
};
