const deepLinkRegex = [
  /^\/$/,
  /^\/q\/.*/,
  /^\/explore/,
  /^\/myJournal/,
  /^\/course\/.*/,
  /^\/preview\/.*/,
];

export default deepLinkRegex;
