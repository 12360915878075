import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";

export const DuplicateOutlineIcon = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        fill={props.fill}
        d="M16,1H4C2.9,1,2,1.9,2,3v14h2V3h12V1z M19,5H8C6.9,5,6,5.9,6,7v14c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2V7 C21,5.9,20.1,5,19,5z M19,21H8V7h11V21z"
      />
    </g>
  </SvgIcon>
);

export default DuplicateOutlineIcon;
