import * as React from "react";

const Marketing = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25">
      <path
        fill={props.fill || "#3F3F3F"}
        d="M18.36 9.993l.6 3H5.04l.6-3h12.72zm1.64-5H4v2h16v-2zm0 3H4l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5zm-14 11v-4h6v4H6z"
      />
    </svg>
  );
};

export default Marketing;
