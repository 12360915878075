import { GetAuthTokenConfig } from "@utils/api-utils";
import nodeAPI from "@utils/axios/node-api";
import qs from "querystringify";

export const nodeUserStatus = (ctx = {}) => {
  if (ctx.cookies === undefined) {
    return {
      token: "",
      email: "",
      avatar: "",
      name: "",
      loginType: "",
    };
  }
  if (ctx.cookies) {
    return {
      token: ctx.cookies.get("token") || "",
      email: ctx.cookies.get("email") || "",
      avatar: decodeURIComponent(ctx.cookies.get("avatar")) || "",
      name: decodeURIComponent(ctx.cookies.get("name")) || "",
      loginType: ctx.cookies.get("loginType") || "",
    };
  }
  return {
    token: "",
    email: "",
    avatar: "",
    firstName: "",
    name: "",
    loginType: "",
  };
};

export const GetCtxAuthTokenConfig = (ctx) => {
  const token = ctx.cookies.get("token");
  if (typeof token !== "undefined") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {};
};

export const GetNextAuthTokenConfig = (ctx) => {
  const { token } = ctx.cookies;
  if (typeof token !== "undefined") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {};
};

export const getUserPrivacy = async (loginStatus) =>
  nodeAPI
    .get("/api/v1/user/privacy", GetAuthTokenConfig(loginStatus))
    .then((response) => response.data)
    .catch((error) => ({
      error,
    }));

export const getUserPrivilege = async (loginStatus) =>
  nodeAPI
    .get("/api/v1/user/privilege", GetAuthTokenConfig(loginStatus))
    .then((response) => {
      const { data } = response.data;
      return {
        data: data.map((item) => item.key),
      };
    })
    .catch((error) => ({
      error,
    }));

export const getLearningSyllabus = async ({
  ctx,
  courseId,
  logError = true,
}) => {
  try {
    const query = qs.stringify({
      courseId,
    });
    const { data } = await nodeAPI.get(
      `/api/v1/learning-system/course/syllabus?${query}`,
      GetCtxAuthTokenConfig(ctx),
    );
    return data;
  } catch (error) {
    if (logError) {
      console.error(`getLearningSyllabus`, error);
    }
    return error;
  }
};

export const getLearningSyllabusNode = async ({
  ctx,
  courseId,
  logError = true,
}) => {
  try {
    const query = qs.stringify({
      courseId,
    });
    const { data, error } = await nodeAPI.get(
      `/api/v1/learning-system/course/syllabus?${query}`,
      GetNextAuthTokenConfig(ctx.req),
    );
    console.log("getLearningSyllabusNode", { data, error });
    return { data, error };
  } catch (error) {
    if (logError) {
      console.error(`getLearningSyllabus`, { error });
    }
    return { error };
  }
};
