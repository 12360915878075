import * as React from "react";

export const QuizIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.25 17.336H14a1.671 1.671 0 0 0 1.666-1.667v-1.333l-1.667 1.333-1.75 1.667ZM5.832.669H1.999c-.3.088-.583.229-.833.417a4.724 4.724 0 0 0-.333.583c-.095.21-.151.436-.167.667v13.33c.016.231.072.457.167.667.091.18.203.347.333.5a1.16 1.16 0 0 0 .833.417h4.667c-.667-.5-.417-1.667-.417-1.667H2.336V2.336h11.666v4.833s1-.583 1.667-.25V2.336A1.671 1.671 0 0 0 14.002.669h-8.17Z"
      fill="currentColor"
    />
    <path
      d="M8.586 17.25a1.384 1.384 0 0 1-1.333-1.417.902.902 0 0 1 .083-.416c.09-.352.23-.69.417-1l.333-.75c0-.084.084-.167.084-.334 0-.083.083-.166.166-.25a.292.292 0 0 0 .084-.166.084.084 0 0 1 .083-.084l5.166-4.5a2.547 2.547 0 0 1 1.667-.583c.298-.007.59.08.833.25.288.14.544.338.75.583l.084.084a2.222 2.222 0 0 1 .5 1.666 2.368 2.368 0 0 1-.75 1.584l-5.167 4.5-.167.166c.084-.083 0-.083 0-.083a.083.083 0 0 0-.083.083h-.167c-.535.108-1.063.247-1.583.417l-.583.167H8.92a.629.629 0 0 1-.334.083Zm1.167-3.167c0 .084-.083.167-.083.25l-.334.75c-.083.167-.083.25-.166.334.466-.136.939-.248 1.416-.334l5.083-4.416a.712.712 0 0 0 .25-.417.992.992 0 0 0-.166-.5c-.084-.083-.084-.083-.167-.083h-.083c-.084 0-.167-.084-.25-.084a1.188 1.188 0 0 0-.5.167l-5 4.333ZM5.333 13.167a.5.5 0 0 1-.5-.25l-1-1.334A.592.592 0 0 1 4 10.667a.593.593 0 0 1 .917.166l.417.584 1.25-1.584a.651.651 0 0 1 1 .834L5.75 13a.635.635 0 0 1-.416.167ZM4.166 8a1.188 1.188 0 0 1-.5-.167.653.653 0 0 1 0-.916l2.667-2.584a.648.648 0 0 1 .916.917L4.583 7.833C4.499 8 4.336 8 4.166 8Z"
      fill="currentColor"
    />
    <path
      d="M6.836 8a1.184 1.184 0 0 1-.5-.167L3.752 5.25a.649.649 0 0 1 .917-.917l2.667 2.584a.654.654 0 0 1 0 .916.613.613 0 0 1-.5.167ZM12.917 4.167H9V5.5h3.917V4.167ZM12.917 6.5H9v1.333h3.917V6.5Z"
      fill="currentColor"
    />
  </svg>
);

export default QuizIcon;
