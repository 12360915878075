import { createContext, useContext } from "react";

const context = createContext<any>({
  header: {
    category: [],
  },
} as any);

export const useAppContext = () => useContext<any>(context);
export default context;
export const { Provider, Consumer } = context;
