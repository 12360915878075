import * as React from "react";

const pptSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill={props.fill || "#3F3F3F"}
      {...props}>
      <path
        fill={props.fill || "#3F3F3F"}
        d="M14.2 20.6H5.3a3.196 3.196 0 01-2.3-1 3.946 3.946 0 01-1-2.5v-6.2a3.582 3.582 0 01.9-2.4l.1-.1a3.524 3.524 0 012.3-.9h8.9c.88.011 1.73.33 2.4.9l.1.1c.586.662.906 1.516.9 2.4v6.2a3.656 3.656 0 01-.9 2.5 4.024 4.024 0 01-2.5 1zM4.3 9.8c-.204.33-.308.712-.3 1.1v6.2a1.512 1.512 0 00.4 1.1c.269.258.627.402 1 .4h8.9a1.86 1.86 0 001-.5 1.49 1.49 0 00.3-1v-6.2a1.666 1.666 0 00-.4-1.1 1.776 1.776 0 00-1-.4H5.3a2.111 2.111 0 00-1 .4z"
      />
      <path
        fill={props.fill || "#3F3F3F"}
        d="M21.2 4l-.1-.1a3.524 3.524 0 00-2.3-.9h-9c-.925.03-1.81.384-2.5 1a5.706 5.706 0 00-.9 2.2h2c.072-.293.208-.567.4-.8.274-.25.63-.392 1-.4h8.9c.373-.002.731.142 1 .4.203.293.308.643.3 1v6.2a1.49 1.49 0 01-.3 1c-.253.24-.562.412-.9.5v2a3.108 3.108 0 002.3-1.1c.618-.682.942-1.58.9-2.5V6.4a4.575 4.575 0 00-.8-2.4z"
      />
    </svg>
  );
};

export default pptSVG;
