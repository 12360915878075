import * as React from "react";

const Public = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 2a10 10 0 1010 10A10 10 0 0012 2zM4 12a8.028 8.028 0 01.21-1.78L8.99 15v1a2.006 2.006 0 002 2v1.93A8.012 8.012 0 014 12zm13.89 5.4a1.993 1.993 0 00-1.9-1.4h-1v-3a1 1 0 00-1-1h-6v-2h2a1 1 0 001-1V7h2a2.006 2.006 0 002-2v-.41a7.978 7.978 0 012.9 12.81z"
        fill={props.fill || "#727171"}
      />
    </svg>
  );
};

export default Public;
