import * as React from "react";

const ArrowRightSVG = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.25.168L9.193 1.343l3.434 3.825H.5v1.667h12.127L9.185 10.66l1.065 1.175L15.5 6 10.25.168z"
        fill={props.fill || "#FFC307"}
      />
    </svg>
  );
};

export default ArrowRightSVG;
