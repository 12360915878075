import * as React from "react";

const LeftArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 34"
      {...props}>
      <path stroke="#fff" strokeWidth={3} d="M14 33L2 17.8 14 1" />
    </svg>
  );
};
const RightArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 34"
      {...props}>
      <path stroke="#fff" strokeWidth={3} d="M2 1l12 15.2L2 33" />
    </svg>
  );
};
export { LeftArrow, RightArrow };
