import isAlpha from "validator/lib/isAlpha";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import isAscii from "validator/lib/isAscii";
import isDate from "validator/lib/isDate";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isFullWidth from "validator/lib/isFullWidth";
import _isLength from "validator/lib/isLength";
import isNumeric from "validator/lib/isNumeric";
import isURL from "validator/lib/isURL";

const isWhiteSpace = (text) =>
  // eslint-disable-next-line camelcase
  isEmpty(text, { ignore_whitespace: true });
const isDigit = (text) => isNumeric(text, { no_symbols: true });
export { isAlphanumeric };
export { isDate };
export {
  isAlpha,
  isAscii,
  isDigit,
  isEmail,
  isEmpty,
  isFullWidth,
  isNumeric,
  isWhiteSpace,
};

export const isLength = (text, options = { min: 0, max: undefined }) =>
  _isLength(text, options);
export const isFirstSpace = (text) => text.length >= 1 && isWhiteSpace(text[0]);

export const isValidHttpUrl = (string) => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  return res !== null;
};

export const textSlicer = ({ string, length }) => {
  if (string && string.length > length) {
    return `${string.slice(0, length - 3)}...`;
  }
  return string;
};

export const checkTitleCompleted = (title) =>
  !/(^[\s]+)/g.test(title) && title && !!title.trim();

export const isValidUrl = (url) =>
  isURL(url, {
    protocols: ["http", "https"],
    require_valid_protocol: true,
    require_protocol: true,
  });

export const getPathFromUrl = (url) => {
  return url.split("?")[0];
};

export const isTextTruncated = (elementRef) => {
  const { scrollHeight, clientHeight, scrollWidth, clientWidth } =
    elementRef.current;
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

export const trimString = (length, string) => {
  if (!string) return "";
  if (string.length > length) {
    return `${string.substr(0, length)}...`;
  }
  return string;
};

export const upperCaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
