import { NEXT_PUBLIC_DEFAULT_COURSE_COVER } from "@components/AppEnv";
import ImageWithFallback from "@components/ImageWithFallback";
import fileSize from "@constants/file-size";
import ClosedCaptionIcon from "@material-ui/icons/ClosedCaption";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import * as S from "@styles/components/_common.module.scss";
import { iconManager } from "@utils/icon-manager";
import React from "react";

export const compressImage = async (imageFile, type = "category") => {
  let maxWidth = "";

  switch (type) {
    case "avatar":
      maxWidth = "256";
      break;
    case "logo":
      maxWidth = "380";
      break;
    case "signature":
      maxWidth = "220";
      break;
    case "category":
      maxWidth = "1350";
      break;
    case "asmt":
      maxWidth = "872";
      break;
    case "webLink":
      maxWidth = "240";
      break;
    case "cover":
    default:
      maxWidth = "1920";
      break;
  }

  try {
    // eslint-disable-next-line global-require
    const imageCompression = require("browser-image-compression").default;
    const maxImageSize = 1 * fileSize.oneMB;
    if (imageFile.size > maxImageSize) {
      return await imageCompression(imageFile, {
        maxSizeMB: 1,
        maxWidthOrHeight: maxWidth,
        useWebWorker: true,
      });
    }
    return imageFile;
  } catch (error) {
    console.error("imageCompression", error);
    return imageFile;
  }
};

export const parseBackgroundImage = (imageUrl) => ({
  backgroundImage: `url(${encodeURI(imageUrl)})`,
  backgroundSize: "cover",
});

export const getFileIcon = (type) => {
  if (type === undefined || type === null) {
    return null;
  }
  if (type === "attachment") {
    return iconManager("FileAttachment", S.gray300);
  }
  const typeName = type.toLowerCase();
  if (typeName.includes("image")) {
    return <PhotoOutlinedIcon />;
  }
  if (typeName.includes("video")) {
    return <SlideshowIcon />;
  }
  if (
    [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xlsx",
    ].indexOf(typeName) !== -1
  ) {
    return <PollOutlinedIcon />;
  }
  if (typeName.includes("document")) {
    return <DescriptionOutlinedIcon />;
  }
  if (typeName.includes("pdf")) {
    return <FilterNoneIcon />;
  }
  if (typeName.includes("ppt")) {
    return iconManager("PPT", S.gray300);
  }
  if (typeName.includes("srt")) {
    return <ClosedCaptionIcon />;
  }
  return <InsertDriveFileOutlinedIcon />;
};

export const defaultThumbnail = (type) => {
  switch (type) {
    case "assessment":
      return "/images/asmtThumbnail.png";
    case "document":
      return "/images/docPreview1.png";
    case "web_link":
      return "/images/webLinkDefaultThumbnail.svg";
    case "survey":
      return "/images/surveyCover.png";
    case "certificate":
      return "/images/CertificateThumbnail.png";
    case "course":
    default:
      return NEXT_PUBLIC_DEFAULT_COURSE_COVER !== "" &&
        typeof NEXT_PUBLIC_DEFAULT_COURSE_COVER !== "undefined"
        ? NEXT_PUBLIC_DEFAULT_COURSE_COVER
        : "/images/course_default_169.svg";
  }
};

const priorityImageList = ["largeUrl", "mediumUrl", "defaultUrl", "smallUrl"];

const getAlternate = (imageObject) => {
  imageObject.forEach((image, index) => {
    if (image[priorityImageList[index]]) {
      return image[priorityImageList[index]];
    }
    throw new Error("Unknown image size");
  });
};

export const parseCoverImageUrl = (item, type = "default") => {
  const wideObject = item?.wide;
  if (wideObject) {
    switch (type) {
      case "large":
        if (wideObject.largeUrl) {
          return wideObject.largeUrl;
        }
        return getAlternate(wideObject);

      case "medium":
        if (wideObject.mediumUrl) {
          return wideObject.mediumUrl;
        }
        return getAlternate(wideObject);

      case "small":
        if (wideObject.smallUrl) {
          return wideObject.smallUrl;
        }
        return getAlternate(wideObject);
      case "default":
      default:
        if (wideObject.defaultUrl) {
          return wideObject.defaultUrl;
        }
        return getAlternate(wideObject);
    }
  }
  return "";
};

const getTargetImageUrl = (image, ratio = "wide", size = "medium") => {
  if (
    typeof image !== "undefined" &&
    image !== null &&
    (ratio === "wide" || ratio === "standard")
  ) {
    switch (size) {
      case "small":
        return image[ratio]?.smallUrl || getAlternate(image[ratio]);
      case "medium":
        return image[ratio]?.mediumUrl || getAlternate(image[ratio]);
      case "large":
        return image[ratio]?.largeUrl || getAlternate(image[ratio]);
      case "default":
      default:
        return image[ratio]?.defaultUrl || getAlternate(image[ratio]);
    }
  }
  return "";
};

export const CustomCourseCover = ({
  image,
  ratio = "wide",
  size = "medium",
  type = "course",
  ...props
}) => {
  const src = image
    ? getTargetImageUrl(image, ratio, size) || defaultThumbnail(type)
    : defaultThumbnail(type);

  return (
    <ImageWithFallback
      src={src}
      className="object-cover"
      alt={`cover_${type}`}
      fallbackSrc={defaultThumbnail(type)}
      fill
      {...props}
    />
  );
};

export const getBgCSSCourseCoverImg = (
  coverImg,
  size = "medium",
  ratio = "wide",
) => {
  if (
    NEXT_PUBLIC_DEFAULT_COURSE_COVER !== "" &&
    typeof NEXT_PUBLIC_DEFAULT_COURSE_COVER !== "undefined"
  ) {
    return {
      backgroundImage: `url(${
        coverImg
          ? getTargetImageUrl(coverImg, ratio, size)
          : NEXT_PUBLIC_DEFAULT_COURSE_COVER
      }), url("/images/course_default_169.svg")`,
    };
  }
  return {
    backgroundImage: `url(${
      coverImg
        ? getTargetImageUrl(coverImg, ratio, size)
        : "/images/course_default_169.svg"
    }), url("/images/course_default_169.svg")`,
  };
};
