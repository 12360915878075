import * as React from "react";

const HFiveSVG = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill={props.fill || "#9FA0A0"}
        d="M22.786 1.002H1.214c-.162-.01-.324.015-.475.073-.152.057-.289.146-.404.26-.114.115-.203.252-.26.404-.058.151-.083.313-.073.475v18.905c-.01.162.015.324.072.476.058.151.147.289.261.403.115.115.252.204.404.261.151.058.313.083.475.073h21.572c.303.018.601-.085.829-.287.227-.202.365-.485.383-.788.003-.046.003-.092 0-.138V2.217c0-.16-.03-.317-.091-.464-.06-.148-.15-.282-.263-.395-.112-.113-.246-.202-.393-.263-.147-.062-.305-.093-.465-.093zm-1.212 2.424v2.182H2.43V3.426h19.143zM2.43 19.908V8.034h19.147v11.753H2.431v.12z"
      />
      <path
        fill={props.fill || "#9FA0A0"}
        d="M8.485 10.819c-.106-.112-.233-.201-.374-.264-.14-.062-.292-.096-.446-.1-.153-.003-.306.023-.45.079-.143.055-.275.138-.386.245-.014.012-.027.026-.04.04l-2.424 2.424c-.225.214-.355.509-.363.82-.007.31.109.61.323.836l.04.04 2.424 2.424c.23.22.53.349.848.364.243 0 .727 0 .848-.243.112-.106.201-.233.263-.374.063-.14.097-.293.1-.446.004-.154-.022-.307-.078-.451-.055-.144-.139-.275-.245-.386l-.04-.04-1.575-1.696 1.575-1.575c.112-.107.201-.234.263-.374.063-.141.097-.293.1-.447.004-.154-.022-.307-.078-.45-.055-.144-.139-.275-.245-.386l-.04-.04zM17.09 10.819c-.225-.226-.53-.352-.849-.352-.318 0-.623.127-.848.351-.225.226-.351.53-.351.849 0 .318.126.623.351.848l1.576 1.576-1.576 1.575c-.111.106-.2.233-.263.374-.062.14-.096.292-.1.446-.003.154.023.307.079.45.055.144.139.276.245.387.013.014.025.027.04.04.214.225.509.355.82.363.31.007.61-.109.836-.323l.04-.04 2.424-2.424c.225-.215.356-.51.363-.82.008-.311-.108-.612-.323-.837l-.04-.04-2.424-2.424zM12.97 10.216c-.305-.066-.623-.016-.893.141-.269.157-.47.41-.561.708l-1.455 5.453c-.066.305-.015.623.142.892.157.27.409.47.706.563h.364c.26-.02.509-.11.722-.26.213-.149.382-.352.49-.59l1.454-5.574c.047-.305-.028-.616-.21-.866-.181-.25-.454-.418-.76-.467z"
      />
    </svg>
  );
};

export default HFiveSVG;
