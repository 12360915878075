/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { startTransaction, withScope } from "@sentry/core";
import type { Scope } from "@sentry/hub";
import type { RequestInstrumentationOptions } from "@sentry/tracing";
import { defaultRequestInstrumentationOptions } from "@sentry/tracing";
import type { Integration } from "@sentry/types";
import { logger } from "@sentry/utils";

import { RangeableRequestTracingActions } from "./actions";
import { instrumentOutgoingRequests } from "./request";

/** Integration for Rangeable Request Tracing * */

export interface RequestData {
  type?: string;
  method?: string;
  url?: string;
}

export interface RangeableRequestTracingOptions
  extends RequestInstrumentationOptions {
  /**
   * Trace transaction name to be displayed in the Performance
   * monitoring of Sentry web page.
   *
   * Default: RangeableRequestTracing.name
   */
  transactionName: string;

  /**
   * Determine if it will force the current trace transaction
   * to be restarted when startTrace() is called.
   *
   * Default: true
   */
  forceRestart: boolean;

  /**
   * The urls that match with the given regex will be stored and send back to Sentry.
   *
   * Default: null
   */
  urlMatch?: RegExp;
}

const defaultRangeableRequestTracingOptions = {
  ...defaultRequestInstrumentationOptions,
  forceRestart: true,
  urlMatch: null,
};

export class RangeableRequestTracing implements Integration {
  public static id: string = "rangeable-request-tracing";

  public options: any;

  public name: string = RangeableRequestTracing.id;

  private _scope: Scope;

  public constructor(options?: Partial<RangeableRequestTracingOptions>) {
    this.options = {
      ...defaultRangeableRequestTracingOptions,
      transactionName: this.name,
      ...options,
    };
  }

  public setupOnce(): void {
    RangeableRequestTracingActions.startTrace = ({
      event = "",
      data = {},
    } = {}) => {
      const {
        traceFetch,
        traceXHR,
        tracingOrigins,
        shouldCreateSpanForRequest,
        transactionName,
        forceRestart,
        urlMatch,
      } = this.options;

      if (Boolean(this._scope) && !forceRestart) {
        if (this._scope !== null) {
          const previousTransaction = this._scope.getTransaction();
          if (event && previousTransaction) {
            const span = previousTransaction.startChild({ op: event, data });
            span.finish();
          }
          this._scope.setSpan(previousTransaction);
        } else {
          logger.warn(
            "The trace is in progress. Call to endTrace() to finish current trace.",
          );
        }
      } else {
        withScope((scope) => {
          this._scope = scope as any; // assign a new scope to discard the current one, it indicates the trace will be restarted.
          const transaction = startTransaction({ name: transactionName, data });
          if (event) {
            const span = transaction.startChild({ op: event, data });
            span.finish();
          }
          scope.setSpan(transaction);
          instrumentOutgoingRequests(
            {
              traceFetch,
              traceXHR,
              tracingOrigins,
              shouldCreateSpanForRequest,
            },
            transaction,
            urlMatch,
          );
        });
      }
    };

    RangeableRequestTracingActions.endTrace = ({
      event = "",
      data = {},
    } = {}) => {
      if (!this._scope) {
        logger.warn(
          "No trace gets started. Call to startTrace() to start one.",
        );
        return;
      }
      const transaction = this._scope.getTransaction();
      if (transaction) {
        if (event) {
          transaction.startChild({ op: event, data }).finish();
        }
        transaction.finish();
      }
      this._scope = null as any;
    };

    RangeableRequestTracingActions.isTraceStarted = () => {
      return Boolean(this._scope);
    };

    RangeableRequestTracingActions.getTracedRequests = () => {
      if (!this._scope) {
        logger.warn(
          "No trace gets started. Call to startTrace() to start one.",
        );
        return;
      }
      const transaction = this._scope.getTransaction();
      if (transaction) {
        // TODO: fix spanRecorder not exist in Transaction
        // return transaction.spanRecorder.spans.slice(1).map((s) => {
        //   return s.data as RequestData;
        // });
        return null;
      }
      return null as any;
    };
  }
}
