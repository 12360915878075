import * as React from "react";

const PreviewImages = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.2853 10.7498H15.428V13.083H13.2853V10.7498ZM12.2135 14.25H16.4998V9.58273H12.2135V14.25ZM7.92802 10.7498H10.0708V13.083H7.92877L7.92802 10.7498ZM6.85628 14.25H11.1425V9.58273H6.85628V14.25ZM7.92802 4.91693H10.0708V7.25024H7.92877L7.92802 4.91693ZM6.85628 8.41727H11.1425V3.75H6.85628V8.41727ZM2.57076 4.91693H4.71353V7.25024H2.57076V4.91693ZM1.49902 8.41727H5.78527V3.75H1.49902V8.41727ZM2.57076 10.7505H4.71353V13.0837H2.57076V10.7505ZM1.49902 14.2507H5.78527V9.58347H1.49902V14.2507ZM15.4273 7.25098H13.2845V4.91776H15.4273V7.25098ZM12.2128 3.75073V8.418H16.499V3.75073H12.2128Z"
          fill={props.fill || "#727171"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="15"
            height="10.5"
            fill="white"
            transform="translate(1.5 3.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PreviewImages;
