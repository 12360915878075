import React from "react";

const South = (props) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 11.4987L10.1925 10.3237L6.75 14.1404L6.75 0.665361L5.25 0.665361L5.25 14.1404L1.8075 10.3154L0.750001 11.4987L6 17.332L11.25 11.4987Z"
        fill={props.fill || "#EA645E"}
      />
    </svg>
  );
};

export default South;
