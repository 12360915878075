import * as React from "react";

const BeginnerLevel = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path fill={props.fill || "#666565"} d="M5 13.994h3v6.008H5v-6.008z" />
      <path
        fill="#C9CACA"
        d="M16.997 4.004h3v15.992h-3V4.004zM10.998 8.992h3v11.012h-3V8.992z"
      />
    </svg>
  );
};

export default BeginnerLevel;
