import { useState } from "react";

const useMContext = () => {
  const [bottomTab, setBottomTab] = useState(0);
  return {
    bottomTab,
    setBottomTab,
  };
};

export { useMContext };
