import {
  LeftArrow as categoryLeftArrow,
  RightArrow as categoryRightArrow,
} from "./CategoryArrowSVG";

export { default as AddSubCategory } from "./AddSubCategory";
export { default as AdvancedLevel } from "./AdvancedLevel";
export { default as ArrowCircleDown } from "./ArrowCircleDown";
export { default as ArrowRight } from "./ArrowRightSVG";
export { default as Assessment } from "./AssessmentSVG";
export { default as Attachment } from "./AttachmentSVG";
export { default as BeginnerLevel } from "./BeginnerLevel";
export { default as CampaignWhite } from "./CampaignWhite";
export { default as Certificate } from "./CertificateSVG";
export { default as CheckList } from "./CheckListSVG";
export { default as CircleArrowDown } from "./CircleArrowDown";
export { default as CircleArrowUp } from "./CircleArrowUp";
export { default as CourseRelation } from "./CourseRelation";
export { default as Courseware } from "./CoursewareSVG";
export { default as CreateCourse } from "./CreateCourse";
export { default as CreateCourseWhite } from "./CreateCourseWhite";
export { default as Download } from "./DownloadSVG";
export { default as DragDrop } from "./DragDrop";
export { default as DuplicateOutline } from "./DuplicateOutlineSVG";
export { default as Empty } from "./EmptySVG";
export { default as Exclamation } from "./ExclamationSVG";
export { default as Explanation } from "./Explanation";
export { default as FileAttachment } from "./FileAttachmentSVG";
export { default as Groups } from "./GroupsSVG";
export { default as HFive } from "./HFiveSVG";
export { default as Html } from "./Html";
export { default as HtmlWhite } from "./HtmlWhite";
export { default as InfoSVG } from "./InfoSVG";
export { default as IntermediateLvl } from "./IntermediateLvl";
export { default as IconQuiz5 } from "./Iquiz5";
export { default as LeaderBoard } from "./LeaderboardSVG";
export { default as Marketing } from "./Marketing";
export { default as North } from "./North";
export { default as PPT } from "./pptSVG";
export { default as PreviewImages } from "./PreviewImages";
export { default as Public } from "./Public";
export { default as QuestionMarker } from "./QuestionMarkerIcon";
export { default as Quiz } from "./QuizSvg";
export { default as RightChevron } from "./RightChevron";
export { default as SortArrow } from "./SortArrow";
export { default as South } from "./South";
export { default as Syllabus } from "./SyllabusSVG";
export { default as UnpublishIcon } from "./Unpublish";
export { default as UploadContent } from "./UploadContent";
export { default as UploadFile } from "./UploadFile";
export { default as Upload } from "./UploadSVG";
export { default as WebLink } from "./WebLinkSVG";

export const category = {
  leftArrow: categoryLeftArrow,
  rightArrow: categoryRightArrow,
};
