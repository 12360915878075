import * as React from "react";

const CoursewareSVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={props.fill || "#727171"}
      viewBox="0 0 24 25"
      width="1em"
      height="1em"
      {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          key="CoursewareSVG-icon-path-1"
          fill="#727171"
          d="M10.553 20.257a7.993 7.993 0 00-4.776-1.3 10.617 10.617 0 00-4.126.955.286.286 0 00-.108.022.37.37 0 01-.109.022.468.468 0 01-.434-.438V6.794a7.993 7.993 0 014.776-1.3 7.994 7.994 0 014.777 1.3 7.991 7.991 0 014.776-1.3c1.03-.009 2.054.138 3.039.435.623.19 1.21.484 1.737.868v4.2l-1.737 1.742V7.665a10.03 10.03 0 00-3.039-.435c-1.674.04-3.312.487-4.776 1.3v9.986a9.704 9.704 0 013.168-1.134L11.2 19.91a5.664 5.664 0 00-.642.345h-.005zm9.118-.347a.408.408 0 01-.217-.043 6.52 6.52 0 00-.8-.36l1.45-1.45v1.419a.463.463 0 01-.433.433zm.297-5.306l.534.534-5.258 5.259h-.538v-.534l5.262-5.26zm2.091-3.5a.576.576 0 00-.407.168l-1.063 1.063 2.178 2.178 1.063-1.063a.58.58 0 000-.819l-1.359-1.36a.572.572 0 00-.412-.167zm-2.091 1.853l-6.424 6.424v2.178h2.178l6.422-6.424-2.176-2.178z"
        />
      </g>
    </svg>
  );
};

export default CoursewareSVG;
