import * as React from "react";

const ExclamationSVG = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z"
        fill={props.fill || "#FFAE04"}
      />
      <path d="M10 3H14V15H10V3Z" fill={props.fill || "#FFAE04"} />
    </svg>
  );
};

export default ExclamationSVG;
