import * as React from "react";

const RightChevron = (props) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width="20"
      height="20">
      <path
        d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
        fill={props.fill || "#00adef"}
      />
    </svg>
  );
};

export default RightChevron;
