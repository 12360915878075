import * as React from "react";

const ArrowCircleDown = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.30526 6.86316C9.66316 6.50526 10.3158 6.50526 10.6737 6.86316L14.4632 10.6526C14.8421 11.0316 14.8421 11.6421 14.4632 12.0211C14.0842 12.4 13.4737 12.4 13.0947 12.0211L10 8.92632L6.90526 12.0211C6.71579 12.2105 6.46316 12.3158 6.21053 12.3158C5.95789 12.3158 5.70526 12.2105 5.51579 12.0211C5.13684 11.6421 5.13684 11.0316 5.51579 10.6526L9.30526 6.86316Z"
        fill={props.fill || "#B5B5B6"}
      />
      <path
        d="M10 0C15.5158 0 20 4.48421 20 10C20 15.5158 15.5158 20 10 20C4.48421 20 0 15.5158 0 10C0 4.48421 4.48421 0 10 0ZM10 18.0632C14.4421 18.0632 18.0632 14.4421 18.0632 10C18.0632 5.55789 14.4421 1.93684 10 1.93684C5.55789 1.93684 1.93684 5.55789 1.93684 10C1.93684 14.4421 5.55789 18.0632 10 18.0632Z"
        fill={props.fill || "#B5B5B6"}
      />
    </svg>
  );
};

export default ArrowCircleDown;
