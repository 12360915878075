import * as React from "react";

const CircleArrowDown = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.30526 13.1368C9.66316 13.4947 10.3158 13.4947 10.6737 13.1368L14.4632 9.34737C14.8421 8.96842 14.8421 8.35789 14.4632 7.97895C14.0842 7.6 13.4737 7.6 13.0947 7.97895L10 11.0737L6.90526 7.97895C6.71579 7.78947 6.46316 7.68421 6.21053 7.68421C5.95789 7.68421 5.70526 7.78947 5.51579 7.97895C5.13684 8.35789 5.13684 8.96842 5.51579 9.34737L9.30526 13.1368Z"
        fill={fill || "#B5B5B6"}
      />
      <path
        d="M10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20ZM10 1.93684C14.4421 1.93684 18.0632 5.55789 18.0632 10C18.0632 14.4421 14.4421 18.0632 10 18.0632C5.55789 18.0632 1.93684 14.4421 1.93684 10C1.93684 5.55789 5.55789 1.93684 10 1.93684Z"
        fill={fill || "#B5B5B6"}
      />
    </svg>
  );
};

export default CircleArrowDown;
