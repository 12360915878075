import * as React from "react";

function SyllabusSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}>
      <path
        fill={props.fill || "#9fa0a0"}
        d="M9.6 6.8a3.799 3.799 0 10-7.6 0 3.757 3.757 0 002.9 3.69v3.291a3.757 3.757 0 00-2.9 3.69 3.799 3.799 0 107.6 0c0-.81-.264-1.598-.75-2.246A3.75 3.75 0 006.9 13.88v-3.49A3.866 3.866 0 009.6 6.8zM4 6.8a1.799 1.799 0 012.151-1.765 1.805 1.805 0 011.312 2.454A1.8 1.8 0 015.8 8.6a1.793 1.793 0 01-1.666-1.11A1.83 1.83 0 014 6.8zm3.6 10.571a1.798 1.798 0 01-2.151 1.765 1.793 1.793 0 01-1.414-1.414A1.798 1.798 0 015.8 15.571a1.79 1.79 0 011.8 1.8zM21.908 3.71H11.404v2.001h10.504V3.71zm-3.201 4.085h-7.303v2h7.303v-2zm3.201 6.58H11.404v2.001h10.504v-2.001zm-3.201 3.784h-7.303v2h7.303v-2z"
      />
    </svg>
  );
}

export default SyllabusSVG;
