import { getCourseInfoByServer } from "@utils/apiServices/explore";
import { defaultThumbnail, parseCoverImageUrl } from "@utils/image-utils";

const loginDirectPath = ({ router, localStorage = null, defaultUrl = "/" }) => {
  const path = router.query.redirect_uri || defaultUrl;
  if (localStorage) {
    const result = localStorage.getItem("redirect_uri") || path;
    localStorage.removeItem("redirect_uri");
    return result;
  }
  return path;
};

const getCourseInfoMeta = async (postData) => {
  try {
    const { data, error } = await getCourseInfoByServer(postData);
    const { previewType } = postData;
    if (error) throw error;
    const {
      coverImg,
      title = "",
      description = "",
    } = previewType === "course" ? data.courseInfo : data || {};
    if (data) {
      return {
        ogImage: parseCoverImageUrl(coverImg, "medium"),
        ogTitle: title,
        ogDescription: description,
        previewInfoProp: data,
      };
    }
  } catch (error) {
    console.error(
      `[API Error],/api/v1/catalog/${postData.previewType}/${postData.cardId},${error.status}`,
    );
  }
  return {
    courseInValid: true,
    ogImage: defaultThumbnail("course"),
    ogTitle: "",
    ogDescription: "",
    previewInfoProp: {},
  };
};

export { getCourseInfoMeta, loginDirectPath };
