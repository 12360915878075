import * as React from "react";

const CreateCourse = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <defs></defs>
      <path
        fill={props.fill || "#fff"}
        d="M12.759 16.61a6.775 6.775 0 00-1.351.65V6.357a11.6 11.6 0 015.254-1.451 10.675 10.675 0 013.352.5v5.751a5.448 5.448 0 012 1.351V4.452a6.642 6.642 0 00-2-.951 10.675 10.675 0 00-3.352-.5 10.147 10.147 0 01-10.458 0 8.693 8.693 0 00-5.2 1.451v13.96a.538.538 0 00.5.5c.1 0 .151-.05.251-.05a12.075 12.075 0 014.5-1.051 8.951 8.951 0 015.254 1.452 10.116 10.116 0 011.8-.8 6.075 6.075 0 01-.55-1.853z"
      />
      <path
        fill={props.fill || "#fff"}
        d="M18.763 20.257c-.25 0-.8 0-.8-.55v-2.5h-2.5c-.551 0-.551-.5-.551-.8s0-.8.551-.8h2.5v-2.5c0-.55.5-.55.8-.55.25 0 .8 0 .8.55v2.5h2.5c.55 0 .55.5.55.8s0 .8-.55.8h-2.5v2.5c.001.55-.5.55-.8.55z"
      />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};

export default CreateCourse;
