import type { Hub } from "@sentry/hub";
import { getCurrentHub } from "@sentry/hub";
import type { Options, TraceparentData, Transaction } from "@sentry/types";

export const TRACEPARENT_REGEXP = new RegExp(
  "^[ \\t]*" + // whitespace
    "([0-9a-f]{32})?" + // trace_id
    "-?([0-9a-f]{16})?" + // span_id
    "-?([01])?" + // sampled
    "[ \\t]*$", // whitespace
);

/**
 * Determines if tracing is currently enabled.
 *
 * Tracing is enabled when at least one of `tracesSampleRate` and `tracesSampler` is defined in the SDK config.
 */
export function hasTracingEnabled(
  options: Options | undefined = getCurrentHub().getClient().getOptions(),
  // ?.getOptions(), // buggy
): boolean {
  if (!options) {
    return false;
  }
  return "tracesSampleRate" in options || "tracesSampler" in options;
}

/**
 * Extract transaction context data from a `sentry-trace` header.
 *
 * @param traceparent Traceparent string
 *
 * @returns Object containing data from the header, or undefined if traceparent string is malformed
 */
export function extractTraceparentData(
  traceparent: string,
): TraceparentData | undefined {
  const matches = traceparent.match(TRACEPARENT_REGEXP);
  if (matches) {
    let parentSampled: boolean | undefined;
    if (matches[3] === "1") {
      parentSampled = true;
    } else if (matches[3] === "0") {
      parentSampled = false;
    }
    return {
      traceId: matches[1],
      parentSampled,
      parentSpanId: matches[2],
    };
  }
  return undefined;
}

/** Grabs active transaction off scope, if any */
export function getActiveTransaction<T extends Transaction>(
  hub: Hub = getCurrentHub(),
): T | undefined {
  return hub?.getScope()?.getTransaction() as T | undefined;
}

/**
 * Converts from milliseconds to seconds
 * @param time time in ms
 */
export function msToSec(time: number): number {
  return time / 1000;
}

/**
 * Converts from seconds to milliseconds
 * @param time time in seconds
 */
export function secToMs(time: number): number {
  return time * 1000;
}

// so it can be used in manual instrumentation without necessitating a hard dependency on @sentry/utils
export { stripUrlQueryAndFragment } from "@sentry/utils";
