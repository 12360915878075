import * as React from "react";

const colors = {
  blue: {
    circle: "#CAEAFE",
    icon: "#00ADEF",
  },
  green: {
    circle: "#DDEDCB",
    icon: "#8FC351",
  },
  purple: {
    circle: "#DCD5E6",
    icon: "#9C89B9",
  },
  yellow: {
    circle: "#FFEDB5",
    icon: "#FFC307",
  },
  red: {
    circle: "#FCECEC",
    icon: "#F0938E",
  },
};

const ColorGroups = ({ fill = "blue", size = "medium" }) => {
  const circleColor = colors[fill].circle;
  const iconColor = colors[fill].icon;
  if (size === "small") {
    return (
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx={12} cy={12} r={12} fill={circleColor} />
        <g clipPath="url(#a)">
          <path
            d="M12 12.428a6.05 6.05 0 0 1 2.423.514 1.706 1.706 0 0 1 1.006 1.56v.925H8.572v-.92c0-.674.388-1.291 1.005-1.56a5.965 5.965 0 0 1 2.423-.52Zm-4.571.142c.628 0 1.143-.514 1.143-1.143 0-.628-.515-1.142-1.143-1.142-.629 0-1.143.514-1.143 1.143 0 .628.514 1.142 1.143 1.142Zm.646.629a3.988 3.988 0 0 0-.646-.057 3.97 3.97 0 0 0-1.589.331 1.149 1.149 0 0 0-.697 1.057v.898h2.572v-.92c0-.475.13-.92.36-1.31Zm8.497-.629c.628 0 1.143-.514 1.143-1.143 0-.628-.515-1.142-1.143-1.142-.629 0-1.143.514-1.143 1.143 0 .628.514 1.142 1.143 1.142Zm2.285 1.96a1.15 1.15 0 0 0-.697-1.057 3.97 3.97 0 0 0-1.588-.331 3.99 3.99 0 0 0-.646.057c.228.389.36.834.36 1.309v.92h2.571v-.898ZM12 8.57c.949 0 1.714.766 1.714 1.715 0 .948-.765 1.714-1.714 1.714a1.712 1.712 0 0 1-1.714-1.714c0-.949.766-1.715 1.714-1.715Z"
            fill={iconColor}
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(5.143 5.143)"
              d="M0 0h13.714v13.714H0z"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx={21} cy={21} r={21} fill={circleColor} />
      <path
        d="M21 21.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V27H15v-1.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91zM13 22c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58A2.01 2.01 0 009 25.43V27h4.5v-1.61c0-.83.23-1.61.63-2.29zM29 22c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4 3.43c0-.81-.48-1.53-1.22-1.85A6.95 6.95 0 0029 23c-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V27H33v-1.57zM21 15c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ColorGroups;
