import { useState } from "react";

export const warningDialogErrorStatusList = [
  "groupNotFound",
  "contentNotFound",
  "courseNotFound",
  "courseHasNotBeenEnrolled",
  "syllabusError",
  "403",
];

export const useWarningDialogState = () => {
  const [validCourseId, setValidCourseId] = useState("");
  const [warningType, setWarningType] = useState("");
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [closeDialogRedirection, setCloseDialogRedirection] = useState(true);

  return {
    showWarningDialog,
    setShowWarningDialog,
    warningType,
    setWarningType,
    validCourseId,
    setValidCourseId,
    closeDialogRedirection,
    setCloseDialogRedirection,
  };
};
