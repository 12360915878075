import * as React from "react";

const Unpublish = (props) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.59003L1.41 5.00003L5.24537e-07 6.41003L6 12.41L12 6.41003L10.59 5.00003L6 9.59003ZM12 3.43323e-05L1.08492e-06 3.32832e-05L9.10071e-07 2.00003L12 2.00003L12 3.43323e-05Z"
        fill={props.fill || "#727171"}
      />
    </svg>
  );
};

export default Unpublish;
