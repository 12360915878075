/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
const merge = require("deepmerge");

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
// #####NOTE####
// ONLY DESCRIBE FILED NEED OVERWRITE!!!

// #region DKAM The values should be same as scm.config.js
const dkam = {};
// #endregion

// #region DKAHR
const dkahr = {
  console: {
    learner_progress_report: true,
    campaign_view: false,
    bundle_course: {
      required_section: [],
      required_field: [],
      custom_field: [],
    },
  },
  explore: {
    use_tms_adapter: true,
    show_bundle_courses: false,
  },
  user: {
    login: {
      btn_sso_login: true, // App not used
      btn_ad_login: true,
      btn_non_ad_login: true,
      btn_social_login_fb: false,
      btn_social_login_google: false,
      display_unauthorized: [""],
      form_type_login: "company", // basic|company
      notice_important_name: true,
    },
  },
  footer: {
    custom: "qrcode", // null||qrcode
  },
  profile: {
    form_uneditable: "all",
    form_type_billingInfo: "null",
    form_type_phoneNumber: false,
    form_password_edit: false,
  },
  contactUs: {
    email: "service.academy@deltaww.com",
  },
  studio: {
    course: {
      use_tms_adapter: true,
      custom_search: ["tmsId"],
      permission: ["create"],
      custom_field: ["tmsId", "tmsVersion", "region", "tmsTimeLimit"],
      catergory_hint: "region",
      hidden_section: ["priceSetting", "visibility"],
      required_section: [
        "basicInformation",
        "tagCategory",
        "courseImage",
        "courseSyllabus",
        "feeCampaignDuration",
      ],
      required_field: [
        "title",
        "category",
        "courseImage",
        "chapterTitle",
        "chapterContent",
        "chapterDuration",
        "previewVideoContent",
        "attachment",
        "price",
      ],
      disabled_field: [
        "title",
        "description",
        "learningTarget",
        "domainTags",
        "category",
        "fee",
      ],
    },
    video: {
      required_section: [
        "basicInformation",
        "videoImage",
        "videoSection",
        "videoContent",
      ],
      required_field: ["title", "skillLevel", "videoImage", "videoSource"],
      disabled_field: ["category", "instructors"],
    },
    doc: {
      required_section: ["basicInformation", "docContent"],
      required_field: ["title", "docContent"],
      disabled_field: ["category", "instructors"],
    },
    h5: {
      permission: ["create", "remove"],
      required_section: ["basicInformation"],
      required_field: ["title"],
      disabled_field: ["category"],
    },
  },
};
// #endregion

// #region DKAC
const dkac = {
  course: {
    promote: ["subsidised"],
    more_info_enroll: ["ntu"],
  },
  contactUs: {
    display_type: ["basic", "order"], // basic,order
    email: "copace@ntu.edu.sg",
  },
  profile: {
    form_extra_billingInfo: "citizenship",
  },
};
// #endregion

const dkahrcn = merge(
  dkahr,
  {
    footer: {
      icp: ["苏ICP备2020062150号-2", "苏公网安备 32050902100913号"],
      customized_copyright: "中达电子(江苏)有限公司版权所有",
    },
    system: {
      forceLang: "zh-Hans", // 強制預設為中文
    },
  },
  { arrayMerge: overwriteMerge },
  {
    system: {
      forceLang: "zh-Hans",
    },
  },
);
// #region DKAT
const dkat = {
  profile: {
    form_type_billingInfo: "address_ACRA",
  },
};
// #endregion

const cisbu = {
  course: {
    disable_discussion: true,
  },
  download: {
    show_app_download_screen: false,
  },
};

const itcp = {
  user: {
    login: {
      it_login_error_message: true,
      it_login_contact_email: "IT.ACADEMY.TRAINING@deltaww.com",
    },
  },
  explore: {
    guest_view: false,
  },
};

module.exports = dkam;
// NOSONAR
// module.exports = dkahr;
// module.exports = dkahrcn;
// module.exports = dkac;
// module.exports = dkat;
// module.exports = cisbu;
// module.exports = itcp;
