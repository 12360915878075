import * as React from "react";

const AttachmentSVG = (props) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10 0.00390625H2C0.9 0.00390625 0 0.903906 0 2.00391V18.0039C0 19.1039 0.9 20.0039 2 20.0039H14C15.1 20.0039 16 19.1039 16 18.0039V6.00391L10 0.00390625ZM14 18.0039H2V2.00391H10V6.00391H14V18.0039ZM8 15.0039C6.9 15.0039 6 14.1039 6 13.0039V7.50391C6 7.22391 6.22 7.00391 6.5 7.00391C6.78 7.00391 7 7.22391 7 7.50391V13.0039H9V7.50391C9 6.12391 7.88 5.00391 6.5 5.00391C5.12 5.00391 4 6.12391 4 7.50391V13.0039C4 15.2139 5.79 17.0039 8 17.0039C10.21 17.0039 12 15.2139 12 13.0039V9.00391H10V13.0039C10 14.1039 9.1 15.0039 8 15.0039Z"
        fill={props.fill || "#C9CACA"}
      />
    </svg>
  );
};

export default AttachmentSVG;
