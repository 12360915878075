const colors = {
  // -------- Blue --------

  blue900: "#395798",
  blue800: "#126bc4",
  blue700: "#0083e5",
  blue600: "#0099ef",
  blue500: "#00adef",
  blue400: "#23c2ff",
  blue300: "#56d0ff",
  blue200: "#89deff",
  blue100: "#abe8ff",
  blue50: "#caeafe",

  // -------- Green --------
  green900: "#60a127",
  green800: "#72ae37",
  green700: "#7cb540",
  green600: "#87bd4a",
  green500: "#8fc351",
  green400: "#a0cc6b",
  green300: "#b1d585",
  green200: "#c7e1a8",
  green100: "#ddedcb",
  green50: "#f2f8ea",

  // -------- Purple --------
  purple900: "#5a4483",
  purple800: "#6d5793",
  purple700: "#77619c",
  purple600: "#826ca6",
  purple500: "#8a74ad",
  purple400: "#9c89b9",
  purple300: "#ad9ec6",
  purple200: "#c5bad6",
  purple100: "#dcd5e6",
  purple50: "#f1eef5",

  // -------- Origin --------
  yellow900: "#ffa102",
  yellow800: "#ffae04",
  yellow700: "#ffb505",
  yellow600: "#ffbd06",
  yellow500: "#ffc307",
  yellow400: "#ffcc2c",
  yellow300: "#ffd551",
  yellow200: "#ffe183",
  yellow100: "#ffedb5",
  yellow50: "#fff8e1",

  // -------- Red --------
  red900: "#db3631",
  red800: "#e14842",
  red700: "#e4524c",
  red600: "#e75c56",
  red500: "#ea645e",
  red400: "#ed7b76",
  red300: "#f0938e",
  red200: "#f5b2af",
  red100: "#f9d1cf",
  red50: "#fcecec",

  // -------- Gray --------

  gray900: "#3f3f3f",
  gray800: "#4d4c4c",
  gray700: "#595757",
  gray600: "#666565",
  gray500: "#727171",
  gray400: "#878686",
  gray300: "#9fa0a0",
  gray200: "#b5b5b6",
  gray100: "#c9caca",
  gray50: "#e8e8e8",
  gray30: "#f1f1f1",
  gray15: "#f9f9f9",
  gray01: "#fafafa",
  gray02: "#efefef",
};

module.exports = colors;
