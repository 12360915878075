import { displayUnauthorized } from "@components/AppEnv";

export const publicPageList = [
  "terms",
  "privacy",
  "help",
  "dataPolicy",
  "contactUs",
  "invite",
  "forgetPassword",
];

export const privatePageList = [
  "myJournal",
  "studio",
  "console",
  "myAccount",
  "notification",
  "order",
  "enroll",
];

export const conditionPageList = ["explore"];

export const compatibleMobileList = [
  "/login/invite",
  "/login/forgetPassword",
  "/login/mobile",
];

export const checkPageIsPublic = (theme) => {
  if (conditionPageList.includes(theme)) {
    return displayUnauthorized;
  }
  if (publicPageList.includes(theme)) {
    return true;
  }
  if (privatePageList.includes(theme)) {
    return false;
  }
  return false;
};
