import * as SvgIcon from "@components/SvgIcon";
import { Icon } from "@material-ui/core";
import { upperCaseFirstLetter } from "@utils/text-utils";
import Image from "next/image";
import React from "react";

import EnrollPic from "@/public/icon/Enroll.svg";

const icons = {
  EnrollGray100: () => (
    <Image
      src="/icon/Enroll_gray100.svg"
      alt="Enroll_gray100"
      width={20}
      height={20}
    />
  ),
  Enroll: () => (
    <Image
      src={EnrollPic}
      alt="Enroll"
      width={20}
      height={20}
      style={{ color: "red" }}
    />
  ),
  Copy: () => <Image width={19} height={22} src="/icon/Copy.svg" alt="Copy" />,
  Share: () => (
    <Image src="/icon/Share.svg" alt="Share" width={36} height={36} />
  ),
  LikeBlue: () => (
    <Image src="/icon/LikeBlue.svg" alt="LikeBlue" width={36} height={36} />
  ),
  LikeWhite: () => (
    <Image src="/icon/LikeWhite.svg" alt="LikeWhite" width={36} height={36} />
  ),
  HelpOutline: ({ color }) => <Icon style={{ color }}>help_outline</Icon>,
  ArrowUp: ({ color }) => <Icon style={{ color }}>keyboard_arrow_up</Icon>,
  ArrowDown: ({ color }) => <Icon style={{ color }}>keyboard_arrow_down</Icon>,
  KeyboardArrowRight: ({ color }) => (
    <Icon style={{ color }}>keyboard_arrow_right</Icon>
  ),
  ArrowBack: ({ color }) => <Icon style={{ color }}>arrow_back</Icon>,
  ArrowDropUpward: ({ color }) => <Icon style={{ color }}>arrow_drop_up</Icon>,
  ArrowDropDownward: ({ color }) => (
    <Icon style={{ color }}>arrow_drop_down</Icon>
  ),
  AddCircle: ({ color }) => <Icon style={{ color }}>add_circle</Icon>,
  AutoRenew: ({ color }) => <Icon style={{ color }}>autorenew</Icon>,
  ChevronLeft: ({ color }) => <Icon style={{ color }}>chevron_left</Icon>,
  ChevronRight: ({ color }) => <Icon style={{ color }}>chevron_right</Icon>,
  Computer: ({ color }) => <Icon style={{ color }}>computer</Icon>,
  ExpandMore: ({ color }) => <Icon style={{ color }}>expand_more</Icon>,
  ExpandLess: ({ color }) => <Icon style={{ color }}>expand_less</Icon>,
  KeyboardCapslock: ({ color }) => (
    <Icon style={{ color }}>keyboard_capslock</Icon>
  ),
  Time: ({ color }) => <Icon style={{ color }}>schedule</Icon>,
  Date: ({ color }) => <Icon style={{ color }}>date_range</Icon>,
  Language: ({ color }) => <Icon style={{ color }}>language</Icon>,
  Link: ({ color }) => <Icon style={{ color }}>link</Icon>,
  Video: ({ color }) => <Icon style={{ color }}>slideshow</Icon>,
  Close: ({ color }) => <Icon style={{ color }}>close</Icon>,
  NormalCheck: ({ color }) => <Icon style={{ color }}>check_circle</Icon>,
  Help: ({ color }) => <Icon style={{ color }}>help_outline</Icon>,
  Course: ({ color, size }) => (
    <Icon style={{ color, fontSize: size }}>import_contacts</Icon>
  ),
  SortArrowDown: ({ color }) => <Icon style={{ color }}>arrow_drop_down</Icon>,
  SortArrowUp: ({ color }) => <Icon style={{ color }}>arrow_drop_up</Icon>,
  SortArrowRight: ({ color }) => <Icon style={{ color }}>arrow_right</Icon>,
  SortArrowLeft: ({ color }) => <Icon style={{ color }}>arrow_left</Icon>,
  Update: ({ color }) => <Icon style={{ color }}>update</Icon>,
  MoreHoriz: ({ color }) => <Icon style={{ color }}>more_horiz</Icon>,
  PlaylistAdd: ({ color }) => <Icon style={{ color }}>playlist_add</Icon>,
  Phone: ({ color }) => <Icon style={{ color }}>phone_iphone</Icon>,
  Error: ({ color }) => <Icon style={{ color }}>error</Icon>,
  IndeterminateCheck: ({ color }) => (
    <Icon style={{ color }}>indeterminate_check_box</Icon>
  ),
  BarChartOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      assessment
    </Icon>
  ),
  DeleteOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      delete
    </Icon>
  ),
  ErrorOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      error_outline
    </Icon>
  ),
  EditOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      edit
    </Icon>
  ),
  FilterOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      filter_alt
    </Icon>
  ),
  LightbulbOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      lightbulb
    </Icon>
  ),
  ViewListOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      view_list
    </Icon>
  ),
  GridOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      grid_view
    </Icon>
  ),
  SendOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      send
    </Icon>
  ),
  DownloadOutLine: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      file_download
    </Icon>
  ),
  Image: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      image
    </Icon>
  ),
  InfoOutline: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      info
    </Icon>
  ),
  RateReviewOutLine: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      rate_review
    </Icon>
  ),
  Lock: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      lock
    </Icon>
  ),
  Document: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      description
    </Icon>
  ),
  Caption: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      closed_caption
    </Icon>
  ),
  Views: ({ color }) => (
    <Icon style={{ color }} className="material-icons-outlined">
      visibility
    </Icon>
  ),
};

export const iconManager = (icon, color = null, size = null) => {
  switch (upperCaseFirstLetter(icon)) {
    case "RightChevron":
      return SvgIcon.RightChevron({ fill: color });
    case "Marketing":
      return SvgIcon.Marketing({ fill: color });
    case "IntermediateLvl":
      return SvgIcon.IntermediateLvl({ fill: color });
    case "Unpublish":
      return SvgIcon.UnpublishIcon({ fill: color });
    case "Html":
      return SvgIcon.Html({ fill: color });
    case "Public":
      return SvgIcon.Public({ fill: color });
    case "HtmlWhite":
      return SvgIcon.HtmlWhite({ fill: color });
    case "CreateCourseWhite":
      return SvgIcon.CreateCourseWhite({ fill: color });
    case "CampaignWhite":
      return SvgIcon.CampaignWhite({ fill: color });
    case "CircleArrowUp":
      return SvgIcon.CircleArrowUp({ fill: color });
    case "CircleArrowDown":
      return SvgIcon.CircleArrowDown({ fill: color });
    case "Explanation":
      return SvgIcon.Explanation({ fill: color });
    case "QuizWhite":
      return SvgIcon.IconQuiz5({ fill: color });
    case "North":
      return SvgIcon.North({ fill: color });
    case "South":
      return SvgIcon.South({ fill: color });
    case "SortArrow":
      return SvgIcon.SortArrow({ fill: color });
    case "PreviewImages":
      return SvgIcon.PreviewImages({ fill: color });
    case "ArrowCircleDown":
      return SvgIcon.ArrowCircleDown({ fill: color });
    case "DragDrop":
      return SvgIcon.DragDrop({ fill: color });
    case "Syllabus":
      return SvgIcon.Syllabus({ fill: color });
    case "FileAttachment":
      return SvgIcon.FileAttachment({ fill: color });
    case "HFive":
      return SvgIcon.HFive({ fill: color });
    case "WebLink":
      return SvgIcon.WebLink({ fill: color });
    case "ExclamationMark":
      return SvgIcon.Exclamation({ fill: color });
    case "Leaderboard":
      return SvgIcon.LeaderBoard({ fill: color });
    case "PPT":
      return SvgIcon.PPT({ fill: color });
    case "AdvancedLvl":
      return SvgIcon.AdvancedLevel({ fill: color });
    case "BeginnerLvl":
      return SvgIcon.BeginnerLevel({ fill: color });
    case "Assessment":
      return SvgIcon.Assessment({ fill: color });
    case "ArrowRight":
      return SvgIcon.ArrowRight({ fill: color });
    case "Courseware":
      return SvgIcon.Courseware({ fill: color });
    case "CourseRelation":
      return SvgIcon.CourseRelation({ fill: color });
    case "Certificate":
      return SvgIcon.Certificate({ fill: color });
    case "Download":
      return SvgIcon.Download({ fill: color });
    case "Upload":
      return SvgIcon.Upload({ fill: color });
    case "Empty":
      return SvgIcon.Empty({ fill: color });
    case "Groups":
      return SvgIcon.Groups({ fill: color });
    case "UploadFile":
      return SvgIcon.UploadFile({ fill: color });
    case "UploadContent":
      return SvgIcon.UploadContent({ fill: color });
    case "Quiz":
      return SvgIcon.Quiz({ fill: color });
    case "QuestionMarker":
      return SvgIcon.QuestionMarker({ fill: color });
    case "CheckList":
      return SvgIcon.CheckList({ fill: color });
    case "AddSubCategory":
      return SvgIcon.AddSubCategory({ fill: color });
    case "DuplicateOutline":
      return SvgIcon.DuplicateOutline({ fill: color });
    case "Attachment":
      return SvgIcon.Attachment({ fill: color });
    case "Info":
      return SvgIcon.InfoSVG({ fill: color });
    default:
      return icons[upperCaseFirstLetter(icon)]
        ? icons[upperCaseFirstLetter(icon)]({ color, size })
        : null;
  }
};

export default iconManager;
